import { ScmEmbedAttrs } from '../type'

const CANVA_ID_IN_SRC = /([^/]+)\/view/

{
  /* eslint-disable react/no-unknown-property */
}
export const Canva = ({
  formattedPaddingTop,
  ...attrs
}: { formattedPaddingTop: string } & ScmEmbedAttrs) => {
  const { src, 'padding-top': paddingTop, 'link-text': linkText } = attrs

  if (!src || !paddingTop || !formattedPaddingTop) {
    return (
      <p>{`Error: Canva embeds should have non-empty "src" & "padding-top" attributes`}</p>
    )
  }

  const match = CANVA_ID_IN_SRC.exec(src)

  if (!match) {
    return <p>{`Error: could not find ID in url: ${src}`}</p>
  }

  const [, id] = match
  const href = `https://www.canva.com/design/${id}/view?utm_content=${id}&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link`

  return (
    <div className="embed-container-canva" embed-exclude="">
      <div
        embed-exclude=""
        style={{
          borderRadius: '8px',
          boxShadow: '0 2px 8px 0 rgba(63, 69, 81, 0.16)',
          height: '0',
          marginBottom: '0.9em',
          marginTop: '1.6em',
          overflow: 'hidden',
          paddingBottom: '48px',
          paddingTop: formattedPaddingTop,
          position: 'relative',
          width: '100%',
          willChange: 'transform',
        }}
      >
        <iframe
          allow="fullscreen"
          embed-exclude=""
          loading="lazy"
          src={src}
          title={linkText}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            border: 'none',
            padding: '0',
            margin: '0',
          }}
        />
      </div>
      <a href={href} rel="noreferrer" target="_blank">
        {linkText}
      </a>
    </div>
  )
}
