import { useEffect, useRef } from 'react'

import { ScmEmbedAttrs } from '../type'
import { Iframe } from '@scm/ui-component-iframe'

const EVERVIZ_ID_IN_SRC = /\/inject\/([a-zA-Z0-9-]+)/

const createScript = (src?: string) => {
  const script = document.createElement('script')
  script.defer = true
  script.src = src || ''
  return script
}

export const Everviz = (attrs: ScmEmbedAttrs) => {
  const { src, evervizEmbedType, height } = attrs
  const embedContainerRef = useRef<HTMLParagraphElement | null>(null)

  useEffect(() => {
    if (
      src &&
      !embedContainerRef.current?.parentNode?.querySelector(
        `script[src="${src}"]`,
      )
    ) {
      embedContainerRef.current?.parentNode?.append(createScript(src))
    }
  }, [src])

  if (!src) {
    return <p>{`Error: Everviz embeds must have non-empty "src" attribute`}</p>
  }

  const match = EVERVIZ_ID_IN_SRC.exec(src)

  const [, srcId] = match || []

  const embedType = evervizEmbedType?.toLowerCase()

  if (embedType === 'iframe') {
    return (
      <Iframe
        src={src}
        width={'100%'}
        height={height || ''}
        allow="fullscreen"
      />
    )
  }

  if (!srcId) {
    return <p>{`Error: could not find ID in URL: ${src}`}</p>
  }

  return (
    <div
      id={
        evervizEmbedType?.toLowerCase() === 'table'
          ? `everviz-table-${srcId}`
          : `everviz-${srcId}`
      }
      className={`everviz-${srcId}`}
      ref={embedContainerRef}
    />
  )
}
