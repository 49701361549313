import { Fragment } from 'react'

import { Link } from '@scm/ui-component-link'
import { Bem, isInsideBrand, isUrlValid, renderMarkdown } from '@scm/ui-core'

import { RelatedArticleProps } from './type'

const relatedArticle = Bem('RelatedArticle')

export const RelatedArticle = ({
  title,
  content,
  className,
  isSponsored,
}: RelatedArticleProps) => {
  if (content?.length <= 0) return null

  return (
    <p
      data-component="related-article"
      className={relatedArticle({}, [className])}
    >
      <span
        data-testid="related-article-title"
        className={relatedArticle('Title')}
        dangerouslySetInnerHTML={{ __html: `${renderMarkdown(title)}:` }}
      />
      {content.map((item, index) => {
        const { target = '_self', url } = item
        const articleTitle = renderMarkdown(item.title)
        let relation = target === '_blank' ? 'noreferrer' : undefined
        if (isUrlValid(url)) {
          isSponsored = isSponsored && !isInsideBrand(url)
        } else {
          isSponsored = false
        }
        if (isSponsored) {
          relation = relation ? relation + ',sponsored' : 'sponsored'
        }
        return (
          <Fragment key={index}>
            <Link
              to={url ? encodeURI(url) : ''}
              rel={relation}
              target={target}
              className={relatedArticle('RelatedContent')}
              dangerouslySetInnerHTML={{ __html: articleTitle }}
            />
            {index < content.length - 1 ? ', ' : ''}
          </Fragment>
        )
      })}
    </p>
  )
}
