import { useEffect, useRef } from 'react'

const showContent = () => {
  document.body.classList.remove('gptHideContent')
}
export const WelcomeAd = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    let clock = 0
    const interval = setInterval(() => {
      clock += 0.1
      if (ref.current?.hasChildNodes() || clock > 3) {
        setTimeout(showContent, 500)
        clearInterval(interval)
      }
    }, 100)
    return () => {
      clearInterval(interval)
    }
  }, [])
  return <div ref={ref} id="welcome_v" />
}
