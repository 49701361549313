import {
  getIsCookieBannerClosed,
  getIsTargetingCookieSet,
} from '@scm/onetrust-cookie-banner'

import { shouldLoadWelcomeAd } from './guards'
import { adsModel } from './model'
import { sendDataToIasPet } from './sendDataToIasPet'
import {
  AdDisplayStatus,
  AdPos,
  AdsContext,
  SlotInfo,
  Targeting,
} from './types'
import { log } from './log'
import { actions, store } from '@scm/redux-store'

declare global {
  interface Window {
    googletag: typeof googletag
  }
}

export const assignGtag = adsModel.assign({
  gtag: () => window.googletag,
})

export const initGtag = (context: AdsContext) => {
  const gtag = context.gtag!
  if (!gtag) return

  gtag.cmd.push(function () {
    const url = new URL(location.href)
    if (url.searchParams.has('testAd')) {
      gtag
        .pubads()
        .setTargeting('testAd', [url.searchParams.get('testAd') || 'scm2022'])
    }

    if (!shouldLoadWelcomeAd(context)) {
      gtag.pubads().setTargeting('welcad', 'off')
    }

    const isCookieBannerClosed = getIsCookieBannerClosed()
    gtag
      .pubads()
      .setTargeting('gdpr_banner', isCookieBannerClosed ? 'off' : 'on')

    const isTargetingCookieSet = getIsTargetingCookieSet()
    gtag
      .pubads()
      .setPrivacySettings({ nonPersonalizedAds: !isTargetingCookieSet })

    gtag.pubads().collapseEmptyDivs()

    let hash = ''
    const tracker = window?.IIRISTracker
    log.info('initGtag: tracker', tracker)

    const hashedEmail = tracker?.getUHash()
    const domainUserId = tracker?.getDUId()
    log.info('initGtag: hashedEmail', hashedEmail)
    log.info('initGtag: domainUserId', domainUserId)

    if (hashedEmail) {
      log.info('initGtag: setting hashedEmail to', hashedEmail)
      hash = hashedEmail
      gtag.pubads().setTargeting('IIRIS_HE', hashedEmail)
    } else if (domainUserId) {
      log.info('initGtag: setting DUId to', domainUserId)
      hash = domainUserId
      gtag.pubads().setTargeting('IIRISDomainUserID', domainUserId)
    }

    gtag.pubads().setPublisherProvidedId(hash)
    gtag.enableServices()
    store.dispatch(actions.layout.update({ gtagReady: true }))
  })
}

export const showWelcomeAds = (context: AdsContext) => {
  showAd('welcome_v', context)
}

export const showBigskyAd = (context: AdsContext) => {
  showAd('bigsky_v', context)
}

const showAd = (pos: AdPos, context: AdsContext) => {
  log.info('showAd:', pos, context)
  const { targeting, networkId, parentAdUnit, isIasPetEnabled } = context
  const gtag = context.gtag!

  if (!gtag || !targeting) return

  gtag.cmd.push(function () {
    const adUnitPath = `/${networkId}/${parentAdUnit}/${targeting.pattern}`

    log.info('showAd > gtag', pos, Date.now())
    const slot = gtag.defineSlot(adUnitPath, [1, 1], pos)!

    slot
      .setTargeting('pos', [pos])
      .setTargeting('ptype', targeting.ptype)
      .addService(gtag.pubads())!

    //responsiveSizeMapping not implemented (not needed for bigsky and welcomeAd)

    if (targeting.nid) slot.setTargeting('nid', targeting.nid)
    if (targeting.aid) slot.setTargeting('aid', targeting.aid)
    if (targeting.reg) slot.setTargeting('reg', targeting.reg)
    if (targeting.kw) slot.setTargeting('kw', targeting.kw)
    if (targeting.series) slot.setTargeting('series', targeting.series)
    if (targeting.program) slot.setTargeting('program', targeting.program)
    if (targeting.content) slot.setTargeting('content', targeting.content)

    isIasPetEnabled
      ? sendDataToIasPet(() => gtag.display(pos))
      : gtag.display(pos)
  })
}

export const assignNewSlot = adsModel.assign(
  {
    slots: (context, event) => ({
      ...context.slots,
      [event.pos]: {
        id: event.pos,
        sizes: event.sizes,
        responsiveSizeMapping: event.responsiveSizeMapping,
        outOfPage: event.outOfPage,
      },
    }),
  },
  'loadAd',
)

export const assignDestroySlot = adsModel.assign(
  {
    slots: (context, event) => {
      const newSlots = { ...context.slots }
      delete newSlots[event.pos]
      return newSlots
    },
  },
  'destroyAd',
)

export const assignTargeting = adsModel.assign(
  {
    targeting: (_context, { targeting }) => targeting,
  },
  'updateTargeting',
)

export const pageTargeting = (context: AdsContext) => {
  const { gtag, targeting } = context
  if (!gtag) return
  gtag.cmd.push(function () {
    const page = gtag.pubads()
    if (targeting?.pterm) page.setTargeting('pterm', targeting.pterm)
    if (targeting?.sterm) page.setTargeting('sterm', targeting.sterm)
    if (targeting?.contentFormat)
      page.setTargeting('contentFormat', targeting.contentFormat)
    if (targeting?.contributor)
      page.setTargeting('contributor', targeting.contributor)
    if (targeting?.buyersJourney)
      page.setTargeting('buyersJourney', targeting.buyersJourney)
    if (targeting?.isSponsored)
      page.setTargeting('isSponsored', targeting.isSponsored)
    if (targeting?.gatedWithExternalForm)
      page.setTargeting(
        'gatedWithExternalForm',
        targeting.gatedWithExternalForm.toString(),
      )
    if (targeting?.gatedWithSiteReg)
      page.setTargeting(
        'gatedWithSiteReg',
        targeting.gatedWithSiteReg.toString(),
      )
    if (targeting?.paidGating)
      page.setTargeting('paidGating', targeting.paidGating)

    if (targeting?.sponsorName)
      page.setTargeting('sponsorName', targeting.sponsorName)
    if (targeting?.premierName)
      page.setTargeting('premierName', targeting.premierName)
  })
}

export const createSlots = ({
  gtag,
  networkId,
  parentAdUnit,
  targeting,
  isIasPetEnabled,
  slots,
}: {
  gtag: typeof googletag
  networkId: AdsContext['networkId']
  parentAdUnit: AdsContext['parentAdUnit']
  targeting: Targeting
  isIasPetEnabled: boolean
  slots: SlotInfo[]
}) => {
  gtag.cmd.push(function () {
    // eslint-disable-next-line complexity
    slots.forEach(({ pos, sizes, responsiveSizeMapping, outOfPage }) => {
      const adUnitPath = `/${networkId}/${parentAdUnit}/${targeting.pattern}`

      const slot = (
        outOfPage
          ? gtag.defineOutOfPageSlot(adUnitPath, pos)
          : gtag.defineSlot(adUnitPath, sizes, pos)
      )!

      slot
        .setTargeting('pos', [pos])
        .setTargeting('ptype', targeting.ptype)
        .addService(gtag.pubads())!

      if (responsiveSizeMapping.length > 0) {
        const sizeMapping = gtag.sizeMapping()

        responsiveSizeMapping.forEach(({ width, height, sizes }) =>
          sizeMapping.addSize([width, height], sizes),
        )

        slot.defineSizeMapping(sizeMapping.build())
      }

      if (targeting.nid) slot.setTargeting('nid', targeting.nid)
      if (targeting.aid) slot.setTargeting('aid', targeting.aid)
      if (targeting.reg) slot.setTargeting('reg', targeting.reg)
      if (targeting.kw) slot.setTargeting('kw', targeting.kw)
      if (targeting.series) slot.setTargeting('series', targeting.series)
      if (targeting.program) slot.setTargeting('program', targeting.program)
      if (targeting.content) slot.setTargeting('content', targeting.content)

      isIasPetEnabled
        ? sendDataToIasPet(() => gtag.display(pos))
        : gtag.display(pos)
    })
  })
}

export const initialiseSlot = (
  context: AdsContext,
  event: ReturnType<typeof adsModel.events.loadAd>,
) => {
  const gtag = context.gtag!
  if (!gtag) return
  createSlots({
    gtag,
    networkId: context.networkId,
    parentAdUnit: context.parentAdUnit,
    targeting: context.targeting!,
    isIasPetEnabled: Boolean(context.isIasPetEnabled),
    slots: [
      {
        pos: event.pos,
        sizes: event.sizes,
        responsiveSizeMapping: event.responsiveSizeMapping,
        outOfPage: event.outOfPage,
      },
    ],
  })
}

export const destroySlot = (
  context: AdsContext,
  event: ReturnType<typeof adsModel.events.destroyAd>,
) => {
  const gtag = context.gtag!
  const slot = getSlots(gtag).find(
    slot => slot.getSlotElementId() === event.pos,
  )

  if (event.pos === 'wallpaper_v') clearAd.wallPaper()
  if (slot) destroySlots(gtag, [slot])
}

export const refreshSpecificSlots = (
  context: AdsContext,
  ads: AdPos[],
  el?: HTMLDivElement,
) => {
  const gtag = context.gtag!
  if (!gtag) return
  gtag.cmd.push(function () {
    const slots = getSlotsByIds(gtag, ads)
    if (ads.includes('wallpaper_v') && el) clearAd.wallPaper(el)
    // TODO update slots targeting info (ptype, nid)ß
    if (slots.length > 0) gtag.pubads().refresh(slots)
  })
}

export const getSlots = (gtag: typeof googletag) => gtag.pubads().getSlots()

export const getSlotsByIds = (gtag: typeof googletag, ids: string[]) => {
  const slots = getSlots(gtag)
  return slots.filter(slot => ids.includes(slot.getSlotElementId()))
}

export const destroySlots = (
  gtag: typeof googletag,
  slots: googletag.Slot[],
) => {
  if (slots.length > 0) {
    gtag.cmd.push(function () {
      gtag.destroySlots(slots)
    })
  }
}

export const refreshSlots = (context: AdsContext) => {
  const gtag = context.gtag!
  if (!gtag) return
  gtag.cmd.push(function () {
    const slots = gtag.pubads().getSlots()
    // TODO update slots targeting info (ptype, nid)
    if (slots.length > 0) gtag.pubads().refresh(slots)
  })
}

export const reopenConsole = () => {
  const panel = document.getElementById('google_pubconsole_console')
  const panelFilter = document.getElementById('google_pubconsole_filler')

  if (panel) panel.remove()
  if (panelFilter) panelFilter.remove()
  if (panel && panelFilter) {
    window?.googletag?.openConsole?.()
  }
}

export const assignResetGtagAndSlots = adsModel.assign({
  gtag: null,
  // @ts-ignore
  slots: {},
})

export const destroyAllSlotsAndDeleteWindowGtagReference = () => {
  if (
    window.googletag &&
    typeof window.googletag.destroySlots !== 'undefined'
  ) {
    window.googletag.destroySlots()
    // @ts-ignore
    delete window.googletag
  }
}

export const getAdsRender = (
  pos: AdPos,
  googleTag: typeof googletag | null,
  fc: (value: AdDisplayStatus) => void,
) => {
  if (!googleTag) return fc('empty')
  googleTag.cmd.push(function () {
    googleTag
      .pubads()
      .addEventListener('slotRenderEnded', function (event: any) {
        const posTarget = event.slot.getTargeting('pos').toString() as AdPos
        if (posTarget === pos && !event.isEmpty) {
          return fc('show')
        }
      })
  })
  return fc('empty')
}

export const clearAd = {
  wallPaper: (el?: HTMLDivElement) => {
    const element = el || document.body
    element.style.backgroundImage = 'unset'
    element.style.cursor = 'unset'
  },
}
