import { TikTokEmbed, XEmbed, FacebookEmbed } from 'react-social-media-embed'

import { Bem } from '@scm/ui-core'

import { IframeProps } from './type'

const iframe = Bem('Iframe')

// eslint-disable-next-line complexity
export const Iframe = (attrs: IframeProps) => {
  if (attrs.src.match(/^https:\/\/(www\.)?(twitter\.com|x\.com)/)) {
    return (
      <div>
        <XEmbed
          url={attrs.src}
          height={attrs.height ?? undefined}
          width={attrs.width ?? undefined}
          data-testid="iframe-twitter"
        />
      </div>
    )
  }

  if (attrs.src.match(/^(https:\/\/)?(www\.)?tiktok\.com/)) {
    return (
      <div>
        <TikTokEmbed
          url={attrs.src}
          height={attrs.height ?? undefined}
          width={attrs.width ?? undefined}
          data-testid="iframe-tiktok"
        />
      </div>
    )
  }
  if (attrs.src.match(/^https?:\/\/www\.facebook\.com.*\/.+$/)) {
    return (
      <div>
        <FacebookEmbed
          url={attrs.src}
          height={attrs.height ?? undefined}
          width={attrs.width ?? undefined}
          data-testid="iframe-facebook"
        />
      </div>
    )
  }
  const isEmbedVideo = (src: string) => {
    const vimeoAndYoutubeRegex = RegExp(
      '^(http:\\/\\/|https:\\/\\/)(vimeo\\.com|youtu\\.be|www\\.youtube\\.com)\\/([\\w\\/]+)([\\?].*)?$',
    )
    return vimeoAndYoutubeRegex.test(src)
  }

  if (isEmbedVideo(attrs.src)) {
    return (
      <div className={iframe()}>
        <iframe
          title="Embedded content"
          src={attrs.src}
          height={attrs.height ?? undefined}
          width={attrs.width ?? undefined}
          allowFullScreen
          data-testid="iframe-video"
          loading="lazy"
        />
      </div>
    )
  }
  const searchParamStr = new URLSearchParams(
    attrs.searchParams || {},
  ).toString()
  const srcWithParams = `${attrs.src}${
    searchParamStr ? `?${searchParamStr}` : ''
  }`

  const isEmbedInstagram = (src: string) => {
    try {
      const url = new URL(src)
      const allowedHosts = ['instagram.com', 'www.instagram.com']
      return allowedHosts.includes(url.hostname)
    } catch (err) {
      return null
    }
  }
  const getInstaID = (src: string) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:p|reel|tv)\/([A-Za-z0-9_-]+)/
    const matches = src.match(regex)
    if (matches && matches[1]) {
      return matches[1]
    }
    return null
  }
  if (isEmbedInstagram(attrs.src)) {
    const instagramEmbedId = `instagram-embed-${getInstaID(attrs.src)}`
    return (
      <iframe
        title="Embedded content"
        src={srcWithParams}
        height={attrs.height ?? undefined}
        width={attrs.width ?? undefined}
        data-testid="iframe"
        scrolling="no"
        id={instagramEmbedId}
      />
    )
  }

  return (
    <iframe
      title="Embedded content"
      src={srcWithParams}
      height={attrs.height ?? undefined}
      width={attrs.width ?? undefined}
      data-testid="iframe"
      loading="lazy"
      scrolling={isEmbedInstagram(attrs.src) ? 'no' : 'auto'}
    />
  )
}
