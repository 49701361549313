import Cookie from 'js-cookie'

import {
  ConsentGroups,
  OneTrustConsentCookie,
  OneTrustConsentGroup,
} from './type'

const CONSENT_GROUPS = {
  [OneTrustConsentGroup.NECESSARY_COOKIES]: 'necessary',
  [OneTrustConsentGroup.PERFORMANCE_COOKIES]: 'performance',
  [OneTrustConsentGroup.FUNCTIONAL_COOKIES]: 'functional',
  [OneTrustConsentGroup.TARGETING_COOKIES]: 'targeting',
}

export const getIsCookieBannerClosed = (): boolean =>
  !!Cookie.get('OptanonAlertBoxClosed')

export const parseConsentCookie = (): OneTrustConsentCookie | null => {
  const cookieRaw = Cookie.get('OptanonConsent')
  if (!cookieRaw) return null
  const cookie = cookieRaw.split('&')?.reduce((acc, curr) => {
    const [key, value] = curr.split('=')
    return {
      ...acc,
      [key]: value,
    }
  }, {} as OneTrustConsentCookie)

  return cookie
}

export const getIsTargetingCookieSet = (): boolean => {
  const cookie = parseConsentCookie()
  const consentGroups = getConsentGroups(cookie?.groups)
  const targetingCookie = !!consentGroups?.targeting
  return targetingCookie
}

const getConsentGroups = (group?: string) => {
  if (!group) return
  const groups = group.split(',').reduce((acc, curr) => {
    const [key, value] = curr.split(':')
    const groupKey = key as keyof typeof CONSENT_GROUPS
    return {
      ...acc,
      [CONSENT_GROUPS[groupKey]]: !!Number(value),
    }
  }, {} as ConsentGroups)
  return groups
}
