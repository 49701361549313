import { RelatedArticleProps } from '@scm/ui-component-related-article'

export interface ContentTextComponentProps {
  content: ContentInline[]
  variant?: TypographyVariants
  align?: ContentTextAlign
  isParagragh?: boolean
  isSponsored?: boolean
  trackGtmClick?: (href: string) => void
}

export interface ImageContentProps {
  content: IContentImage
  index: number
  isSponsored?: boolean
  caption?: string | null
}

export type Marks = (ContentBaseMarks | ContentCustomMarks)[]

export interface ContentBaseMarks {
  type: 'bold' | 'italic' | 'strike' | 'underline'
}

export type ContentCustomMarksNames = 'link' | 'subscript' | 'superscript'

export type ContentTextAlign = 'left' | 'center' | 'right' | 'justify'

type TagType = 'a' | 'sub' | 'sup' | 'span'
export type TagName = ReturnType<typeof getTagName>
export const getTagName = (type?: string): TagType => {
  if (type === 'link') return 'a'
  if (type === 'subscript') return 'sub'
  if (type === 'superscript') return 'sup'
  return 'span'
}

export type ContentCustomMarks =
  | ContentLinkMark
  | ContentSubscriptMark
  | ContentSuperscriptMark

export interface ContentLinkMark {
  type: 'link'
  attrs: {
    href: string
    target: '_blank' | '_self'
    rel?: string
  }
}

export interface ContentSubscriptMark {
  type: 'subscript'
}

export interface ContentSuperscriptMark {
  type: 'superscript'
}

export interface ContentEmbedCode {
  type: 'embedCode'
  attrs: {
    innerHTML: string
  }
}

export interface ScmEmbedAttrs {
  id?: string
  type?: string
  src?: string
  title?: string
  href?: string
  height?: string
  width?: string
  mediaId?: string
  playerForm?: string
  playerId?: string
  evervizEmbedType?: string
  ['data-aspectratio']?: string
  ['data-mobile-aspectratio']?: string
  ['padding-top']?: string
  ['link-text']?: string
  ['embed-type']?: string
  ['width']?: string
  ['display-mode']?: string
  ['animation-mode']?: string
  ['color']?: string
}

export interface ContentScmEmbedProps {
  type: 'scmEmbed'
  attrs: ScmEmbedAttrs
  content: ContentText[]
}

export interface ContentHardBreak {
  type: 'hardBreak'
}

export interface ContentText {
  type: 'text'
  text: string
  marks?: (ContentBaseMarks | ContentCustomMarks)[]
  attrs?: {
    textAlign?: ContentTextAlign
  }
  isSponsored?: boolean
}

export interface ContentHorizontalRule {
  type: 'horizontalRule'
}

export type ContentInline =
  | ContentText
  | ContentHardBreak
  | IContentImage
  | ContentHorizontalRule
  | ContentEmbedCode

export interface ContentParagraph {
  type: 'paragraph'
  content: ContentParagraph[] | ContentInline[]
  attrs?: {
    textAlign?: ContentTextAlign
  }
  isSponsored?: boolean
}

export interface ContentParagraphProps extends ContentParagraph {
  paragraphVariant?: TypographyVariants
  trackGtmClick?: (href: string) => void
}

export type HeadingLevels = 1 | 2 | 3 | 4 | 5 | 6

export interface ContentHeading {
  type: 'heading'
  attrs: {
    level: HeadingLevels
    textAlign?: ContentTextAlign
  }
  content: ContentInline[]
}

export interface ContentBlockquote {
  type: 'blockquote'
  content: ContentParagraph[]
}

export interface ContentCodeBlock {
  type: 'codeBlock'
  content: ContentParagraph[]
}

export interface ContentListItem {
  type: 'listItem'
  content: (ContentParagraph | ContentUnorderedList | ContentOrderedList)[]
}

export interface ContentUnorderedList {
  type: 'bulletList'
  content: ContentListItem[]
}

export interface ContentOrderedList {
  type: 'orderedList'
  content: ContentListItem[]
}

export interface IContentImage {
  type: 'image'
  attrs: {
    src: string
    alt: string | null
    title: string | null
    textAlign: ContentTextAlign
    style: {
      float?: string
      width?: string
      margin?: string
    }
  }
  marks?: (ContentBaseMarks | ContentLinkMark)[]
}

export interface ContentFigure {
  type: 'figure'
  content: [IContentImage]
  attrs: {
    figcaption: string | null
  }
}

export interface ContentIframe {
  type: 'iframe'
  attrs: {
    allow: string | null
    height: string | null
    src: string
    width: string | null
    scrolling?: string | null
    frameBorder?: string | null
  }
}

export interface ContentTableCell {
  type: 'tableCell' | 'tableHeader'
  attrs?: {
    colspan?: number
    rowspan?: number
    colwidth?: null
  }
  content: ContentParagraph[]
}

export interface ContentTableRow {
  type: 'tableRow'
  content: ContentTableCell[]
}

export interface ContentTable {
  type: 'table'
  content: ContentTableRow[]
  trackGtmClick?: (href: string) => void
}

export interface ContentRelatedArticle extends RelatedArticleProps {
  type: 'relatedArticle'
}

export interface ContentAd {
  type: 'ad'
  pos:
    | '300_1v_article'
    | '300_2v_article'
    | '300_3v_article'
    | 'native_1v'
    | 'native_2v'
    | 'video_v'
    | 'reveal_1v'
}

export type ContentItem =
  | ContentParagraph
  | ContentHeading
  | ContentBlockquote
  | IContentImage
  | ContentFigure
  | ContentRelatedArticle
  | ContentIframe
  | ContentEmbedCode
  | ContentCodeBlock
  | ContentListItem
  | ContentUnorderedList
  | ContentOrderedList
  | ContentTable
  | ContentAd
  | ContentText
  | ContentInline
  | ContentScmEmbedProps

export type ContentJSON = ContentItem[]

export interface ContentProps {
  content: ContentJSON
  doWrap?: boolean
  paragraphVariant?: TypographyVariants
  nestedLevel?: number
  isSponsored?: boolean
  searchParams?: Record<string, string>
  withRevealAds?: boolean
  isParagraph?: boolean
  trackGtmClick?: (href: string) => void
}

export type TypographyVariants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body-normal'
  | 'body-none-style'
  | 'content-table'
