import React, { useCallback, useEffect } from 'react'

import { table } from './table.constants'
import { HeadStickyProps } from './type'

const setElementTop = (element: HTMLElement, top: number) => {
  element.style.top = `${top}px`
}

const setHeaderPosition = (
  refTableContainer?: React.RefObject<HTMLDivElement>,
  headerOffset = 0,
) => {
  const tableElement = refTableContainer?.current
  if (!tableElement) return

  const navigationHeader = document.getElementById('navigation-header')
  if (!navigationHeader) return

  const headElements = tableElement.getElementsByClassName('Table-Head')
  const headElement = headElements?.[0] as HTMLElement
  if (!headElement) return

  const clientRect = tableElement.getBoundingClientRect()
  if (!clientRect) return

  const navigationHeaderBottom =
    navigationHeader.getBoundingClientRect()?.bottom || 0
  const tableTop = clientRect.top - navigationHeaderBottom - headerOffset
  const tableBottom = clientRect.bottom - navigationHeaderBottom - headerOffset

  if (tableTop <= 0 && tableBottom >= 0) {
    setElementTop(headElement, -tableTop)
  } else if (tableTop > 0) {
    setElementTop(headElement, 0)
  }
}

export const HeadSticky = ({
  className,
  refTableContainer,
  headerOffset,
  ...otherProps
}: React.PropsWithChildren<HeadStickyProps>) => {
  const listener = useCallback(
    () => setHeaderPosition(refTableContainer, headerOffset),
    [refTableContainer],
  )

  useEffect(() => {
    window.addEventListener('scroll', listener)
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('scroll', listener)
      window.removeEventListener('resize', listener)
    }
  }, [refTableContainer])

  return <thead className={table('Head', [className])} {...otherProps} />
}
