import { BasicListProps } from './type'

export const isValidListProps = (object: any): object is BasicListProps => {
  return (
    'list' in object &&
    'variant' in object &&
    (object['variant'] === 'ordered' || object['variant'] === 'unordered') &&
    Array.isArray(object['list'])
  )
}
