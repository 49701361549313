import { Bem } from '@scm/ui-core'

import { CodeBlockProps } from './type'

const codeBlock = Bem('CodeBlock')

export const CodeBlock = (props: CodeBlockProps) => {
  return (
    <pre data-component="code-block" className={codeBlock()}>
      {props.children}
    </pre>
  )
}
