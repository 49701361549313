import { memo, useEffect, useRef } from 'react'

import { EmbedsProps } from './type'

const fbRegex = /facebook\.com/i

const isRawHtml = (html: string) => {
  const head = (html || '').trim().slice(0, 100)

  return head.includes('data-type') && head.includes('raw-html')
}

declare global {
  interface Window {
    FB: {
      XFBML: {
        // eslint-disable-next-line @typescript-eslint/ban-types
        parse: () => void
      }
    }
  }
}

export const Embeds = memo(function Embeds(attrs: EmbedsProps) {
  const facebookEmbedElement = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!facebookEmbedElement.current) {
      return
    }
    let timeout: NodeJS.Timeout
    const WAIT_TIME = 5000

    const recoverOnParsed = () => {
      if (facebookEmbedElement.current && window.FB) {
        facebookEmbedElement.current.removeAttribute('fb-iframe-plugin-query')
        facebookEmbedElement.current.removeAttribute('fb-xfbml-state')
        if (facebookEmbedElement.current.children?.length) {
          facebookEmbedElement.current.removeChild(
            facebookEmbedElement.current.children[0],
          )
        }
        window.FB.XFBML.parse()
      }
    }

    const state = facebookEmbedElement.current.getAttribute('fb-xfbml-state')
    if (state === 'parsed') {
      timeout = setTimeout(recoverOnParsed, WAIT_TIME)
    }
    // Callback function to execute when mutations are observed
    // eslint-disable-next-line complexity
    const callback = (mutationList: MutationRecord[]) => {
      for (const mutation of mutationList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'fb-xfbml-state'
        ) {
          const value = (mutation.target as Element).getAttribute(
            'fb-xfbml-state',
          )
          if (value === 'parsed' && !timeout) {
            timeout = setTimeout(recoverOnParsed, WAIT_TIME)
          } else if (value === 'rendered') {
            if (timeout) clearTimeout(timeout)
          }
        }
      }
    }

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback)

    // Start observing the target node for configured mutations
    observer.observe(facebookEmbedElement.current as Node, { attributes: true })

    return () => {
      if (timeout) clearTimeout(timeout)
      // Later, you can stop observing
      observer.disconnect()
    }
  }, [])

  if (!isRawHtml(attrs.innerHTML) && fbRegex.test(attrs.innerHTML)) {
    // Extract the src attribute from the innerHTML
    const fbPostSrc =
      attrs.innerHTML.match(/src="(.*?)"/) &&
      attrs.innerHTML.match(/src="(.*?)"/)![1]

    const urlToEmbed =
      attrs.innerHTML.includes('plugins') && fbPostSrc
        ? getDecodedFacebookUri(fbPostSrc)
        : fbPostSrc

    return (
      <div data-component="embeds">
        <div
          className="fb-post"
          data-href={urlToEmbed}
          data-width="500"
          ref={facebookEmbedElement}
          data-testid="fb-post"
        />
      </div>
    )
  }

  return (
    <div
      data-component="embeds"
      dangerouslySetInnerHTML={{ __html: attrs.innerHTML }}
    />
  )
})

export const getDecodedFacebookUri = (fbPostSrc: string) => {
  const hrefMatch = fbPostSrc.match(/href=/)
  const ampMatch = fbPostSrc.match(/&/)
  if (!hrefMatch || !ampMatch) return fbPostSrc
  const uri = fbPostSrc
    .slice(hrefMatch.index, ampMatch.index)
    .replaceAll('href=', '')
  return decodeURIComponent(uri)
}
