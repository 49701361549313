import { createModel } from '@scm/xstate'

import { AdPos, AdsContext, LoadAdPayload } from './types'

export const initialContext: AdsContext = {
  enabledAds: [],
  // @ts-expect-error
  slots: {},
  gtag: null,
  networkId: 0,
  parentAdUnit: '',
  targeting: null,
  shouldRunDcts: null,
}

export const adsModel = createModel(initialContext, {
  events: {
    adsUnblocked: () => ({}),
    adsBlocked: () => ({}),
    loadAd: (payload: LoadAdPayload) => payload,
    destroyAd: (pos: AdPos) => ({ pos }),
    updateTargeting: (targeting: AdsContext['targeting']) => ({ targeting }),
    showWelcomeAds: (targeting: AdsContext['targeting']) => ({ targeting }),
    showBigskyAd: (targeting: AdsContext['targeting']) => ({ targeting }),
  },
})
