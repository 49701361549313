import { Ads } from '@scm/ads'
import { BasicList } from '@scm/ui-component-basic-list'
import { Blockquote } from '@scm/ui-component-blockquote'
import { CodeBlock } from '@scm/ui-component-code-block'
import { Embeds } from '@scm/ui-component-embeds'
import { Iframe } from '@scm/ui-component-iframe'
import { RelatedArticle } from '@scm/ui-component-related-article'
import { Bem } from '@scm/ui-core'

import { ContentImage } from './contentImage'
import { ContentParagraph } from './contentParagraph'
import { ContentScmEmbed } from './contentScmEmbed'
import { ContentTable } from './contentTable'
import { ContentText } from './contentText'
import { ContentProps } from './type'
import { getContent } from './utils'

export const contentModule = Bem('ContentModule')

export const Content = ({
  content,
  doWrap: doWrap = true,
  paragraphVariant = 'body-normal',
  nestedLevel = 0,
  isSponsored,
  searchParams = {},
  withRevealAds = true,
  isParagraph,
  trackGtmClick,
}: ContentProps) => {
  const renderContent = withRevealAds ? getContent(content) : content

  // eslint-disable-next-line complexity
  const renderedContent = renderContent.map((item, index) => {
    const key = 'k-' + index
    switch (item.type) {
      case 'paragraph':
        return (
          <ContentParagraph
            key={key}
            {...item}
            paragraphVariant={paragraphVariant}
            isSponsored={isSponsored}
            trackGtmClick={trackGtmClick}
          />
        )
      case 'image':
        return (
          <ContentImage
            key={key}
            content={item}
            index={index}
            isSponsored={isSponsored}
          />
        )
      case 'figure':
        return (
          <ContentImage
            key={key}
            content={item.content[0]}
            index={index}
            caption={item.attrs.figcaption}
            isSponsored={isSponsored}
          />
        )
      case 'blockquote':
        return (
          <Blockquote key={key}>
            <Content
              content={item.content}
              doWrap={false}
              paragraphVariant="body-none-style"
              isSponsored={isSponsored}
            />
          </Blockquote>
        )
      case 'codeBlock':
        return (
          <CodeBlock key={key}>
            <Content
              content={item.content}
              doWrap={false}
              paragraphVariant="body-none-style"
              isSponsored={isSponsored}
              isParagraph={true}
            />
          </CodeBlock>
        )
      case 'heading':
        return (
          <ContentText
            key={key}
            content={item.content}
            variant={`h${item.attrs.level}`}
            align={item.attrs.textAlign}
            isSponsored={isSponsored}
          />
        )
      case 'orderedList':
        return (
          <BasicList
            key={key}
            variant="ordered"
            classNames={paragraphVariant}
            nestedLevel={nestedLevel}
            list={item.content.map((item, i) => (
              <Content
                key={i}
                content={[item]}
                paragraphVariant={paragraphVariant}
                doWrap={false}
                nestedLevel={nestedLevel + 1}
                isSponsored={isSponsored}
              />
            ))}
          />
        )
      case 'bulletList':
        return (
          <BasicList
            key={key}
            variant="unordered"
            nestedLevel={nestedLevel}
            list={item.content.map((item, i) => (
              <Content
                key={i}
                content={[item]}
                paragraphVariant={paragraphVariant}
                doWrap={false}
                nestedLevel={nestedLevel + 1}
                isSponsored={isSponsored}
              />
            ))}
          />
        )
      case 'listItem':
        return (
          <Content
            key={key}
            content={item.content}
            paragraphVariant={paragraphVariant}
            doWrap={false}
            nestedLevel={nestedLevel}
            isSponsored={isSponsored}
          />
        )
      case 'relatedArticle':
        return <RelatedArticle key={key} {...item} isSponsored={isSponsored} />
      case 'iframe':
        return <Iframe key={key} searchParams={searchParams} {...item.attrs} />
      case 'embedCode':
        return <Embeds key={key} innerHTML={item.attrs.innerHTML} />
      case 'table':
        return (
          <ContentTable key={key} {...item} trackGtmClick={trackGtmClick} />
        )
      case 'ad': {
        return <Ads.Ad key={key} pos={item.pos} />
      }
      case 'text':
        return (
          <ContentText
            key={key}
            variant={paragraphVariant}
            content={[item]}
            align={item.attrs?.textAlign}
            isSponsored={isSponsored}
            isParagragh={isParagraph}
          />
        )
      case 'scmEmbed':
        return <ContentScmEmbed key={key} {...item} />
      default:
        return null
    }
  })

  if (doWrap) {
    return (
      <div data-module="content" className={contentModule('Wrapper')}>
        {renderedContent}
      </div>
    )
  }

  return <>{renderedContent}</>
}
