import { useEffect, useRef } from 'react'

import { ScmEmbedAttrs } from '../type'

declare global {
  interface HTMLScriptElement {
    ['data-ceros-origin-domains']: string
    ['embed-exclude']: string
  }
}

{
  /* eslint-disable react/no-unknown-property */
}
export const Ceros = (attrs: ScmEmbedAttrs) => {
  const {
    src,
    ['data-aspectratio']: dataAspectratio,
    ['data-mobile-aspectratio']: dataMobileAspectratio,
    id = String(Date.now()),
    title = 'Ceros embed',
  } = attrs
  const scriptContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const script = document.createElement('script')
    script['data-ceros-origin-domains'] = 'view.ceros.com'
    script['embed-exclude'] = ''
    script.src = '//view.ceros.com/scroll-proxy.min.js'
    script.type = 'text/javascript'

    scriptContainerRef.current?.appendChild(script)
  }, [])

  if (!src || !dataAspectratio) {
    return (
      <p>{`Error: Ceros embeds should have non-empty "src" & "data-aspectratio attributes`}</p>
    )
  }

  const embedWidthPercentage = 100
  const paddingBottomPercentage =
    embedWidthPercentage / parseFloat(dataAspectratio)

  return (
    <div className="embed-container-ceros" embed-exclude="">
      <div
        className={id}
        data-aspectratio={dataAspectratio}
        data-mobile-aspectratio={dataMobileAspectratio}
        embed-exclude=""
        id={id}
        style={{
          border: '0 none',
          bottom: 0,
          height: 0,
          left: 0,
          marginBottom: '0px',
          marginLeft: '0px',
          marginRight: '0px',
          marginTop: '0px',
          padding: `0 0 ${paddingBottomPercentage}%`,
          position: 'relative',
          right: 0,
          top: 0,
          width: 'auto',
        }}
      >
        <iframe
          allowFullScreen={false}
          className={`ceros-experience ${id}-iframe`}
          embed-exclude=""
          frameBorder="0"
          scrolling="no"
          src={src}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            margin: 0,
            padding: 0,
            border: '0 none',
            height: '1px',
            width: '1px',
            minHeight: '100%',
            minWidth: '100%',
          }}
          title={title}
        />
      </div>
      <div ref={scriptContainerRef} />

      <style embed-exclude="" type="text/css">
        {`
.${id} {
  position: relative;
  width: auto;
  padding: 0 0 ${paddingBottomPercentage}%;
  height: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  border: 0 none;
}

.${id}-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  border: 0 none;
  height: 1px;
  width: 1px;
  min-height: 100%;
  min-width: 100%;
}
`}
      </style>
    </div>
  )
}
