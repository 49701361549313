import { useEffect, useRef, useState } from 'react'

import { ImageProps } from './type'
import { getImageSrc } from './utils'
import { selectors } from '@scm/redux-store'

export const Image = ({
  src = '', // src="" then onError will be called, if no src then onError won't be called
  fallback,
  alt,
  className,
  width = 700,
  format,
  onlyDataSrc = false, // use to lazyload swiper slide
  disabledUpscale = true,
  quality = 80,
  loading = 'lazy',
  ...props
}: ImageProps) => {
  const { image } = selectors.useConfig()
  const ref = useRef<HTMLImageElement>(null)
  const [isError, setIsError] = useState(false)
  const [isVisible, setState] = useState(false)

  useEffect(() => {
    if (ref.current && !isVisible) {
      if (typeof IntersectionObserver === 'undefined') {
        setState(true)
        return
      }
      const element = ref.current
      const observer = new IntersectionObserver(([entry]) => {
        setState(entry.isIntersecting)
      })

      observer.observe(element)

      return () => {
        observer.unobserve(element)
      }
    }
  }, [isVisible])

  const fallbackImage = image[fallback]

  const imageSrc = getImageSrc({
    src: isError || !src ? fallbackImage : src,
    width,
    format,
    disabledUpscale,
    quality,
  })

  // const blurImageSrc = getImageSrc({
  //   src: isError || !src ? fallbackImage : src,
  //   width,
  //   format,
  //   disabledUpscale,
  //   quality: 10,
  //   blur: 40,
  // })

  return (
    <img
      {...props}
      ref={ref}
      crossOrigin={undefined}
      data-component="image"
      className={className}
      data-src={onlyDataSrc || !isVisible ? imageSrc : undefined}
      src={imageSrc}
      // src={onlyDataSrc ? blurImageSrc : isVisible ? imageSrc : blurImageSrc}
      loading={onlyDataSrc ? 'eager' : loading}
      alt={
        fallback === 'brand' && !src
          ? `${alt} logo in a gray background | ${alt}`
          : alt
      }
      title={alt}
      onError={() => {
        if (isVisible) setIsError(false)
      }}
    />
  )
}
