import { PropsWithChildren } from 'react'

import { table } from './table.constants'
import { ColProps } from './type'

export const Cell = ({
  className,
  children,
  rowSpan,
  colSpan,
  ...otherProps
}: PropsWithChildren<ColProps>) => {
  if (rowSpan === 0 || colSpan === 0) return null

  return (
    <td
      className={table('Cell', [className])}
      rowSpan={rowSpan}
      colSpan={colSpan}
      {...otherProps}
    >
      {children}
    </td>
  )
}
