import { PropsWithChildren } from 'react'

import { table } from './table.constants'
import { RowProps } from './type'

export const Row = ({
  className,
  children,
  ...otherProps
}: PropsWithChildren<RowProps>) => {
  return (
    <tr className={table('Row', [className])} {...otherProps}>
      {children}
    </tr>
  )
}
