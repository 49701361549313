import { FC } from 'react'

import { Image } from '@scm/ui-component-image'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { getDimension } from './contentParagraph'
import { ContentBaseMarks, ContentLinkMark, ImageContentProps } from './type'
import { getClassNameModificators } from './utils'

export const ContentImage: FC<ImageContentProps> = ({
  content,
  index,
  isSponsored,
  caption,
}) => {
  const link = getLink(content.marks)
  const captionText = caption || content.attrs?.title
  const width = content.attrs?.style?.width
  const imgWidth = width ? getDimension(width) : undefined
  const contentImage = Bem('ContentImage')
  if (!link)
    return (
      <div>
        <Image
          key={index}
          fallback="brand"
          src={content.attrs?.src}
          alt={content.attrs?.alt || ''}
          className={contentImage('Image', { align: content.attrs?.textAlign })}
          data-testid="content-image"
          width={imgWidth}
        />
        {captionText && <p className={contentImage('Link')}>{captionText}</p>}
      </div>
    )

  const linkAttrs = {
    className: contentImage('Link'),
    to: link.attrs.href,
    target: link.attrs.target,
    rel: isSponsored ? 'sponsored' : undefined,
  }

  return (
    <div
      className={contentImage('Wrapper', {
        ...getClassNameModificators(content.marks || []),
      })}
    >
      <Image
        key={index}
        fallback="brand"
        src={content.attrs?.src}
        alt={content.attrs?.alt || ''}
        className={contentImage('Image', { align: content.attrs?.textAlign })}
        data-testid="content-image"
        width={imgWidth}
      />
      {captionText ? <Link {...linkAttrs}>{captionText}</Link> : null}
    </div>
  )
}

const getLink = (
  marks?: (ContentBaseMarks | ContentLinkMark)[],
): ContentLinkMark | undefined => {
  if (!Array.isArray(marks)) return
  const link = marks.find(m => m.type === 'link') as ContentLinkMark | undefined
  return link
}
