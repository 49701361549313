import { ContentParagraph, ContentInline } from '../type'

const emptyParagraph: ContentParagraph = {
  type: 'paragraph',
  content: [{ type: 'text', text: '' }],
}

export const cleanupParagraphContent = (
  paragraph: ContentParagraph,
): ContentParagraph => {
  const inline = paragraph as unknown as ContentInline
  if (inline.type === 'embedCode') return emptyParagraph

  const { content } = paragraph
  if (!content) return paragraph

  const filteredContent = content.filter(
    item =>
      (item.type === 'text' && (item.text || '').trim().length > 0) ||
      item.type === 'paragraph',
  ) as ContentParagraph[] | ContentInline[]

  paragraph.content = filteredContent
  return paragraph
}

export const nonEmptyParagraphContent = (paragraph: ContentParagraph) =>
  paragraph.type === 'paragraph' && paragraph.content?.length > 0

export const nonEmptyArray = (array: any[]) =>
  Array.isArray(array) && array.length > 0

export const cleanupTableCell = (cell: ContentParagraph[]) =>
  cell.map(cleanupParagraphContent).filter(nonEmptyParagraphContent)

export const cleanupTableRow = (row: ContentParagraph[][]) =>
  row.map(cleanupTableCell).filter(nonEmptyArray)

export const cleanupTableRows = (rows: ContentParagraph[][][]) =>
  rows.map(cleanupTableRow).filter(nonEmptyArray)
