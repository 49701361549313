import { isValidElement } from 'react'

import { Icon } from '@scm/ui-component-icon'
import { Bem, renderMarkdown } from '@scm/ui-core'

import { BasicListProps } from './type'
import { isValidListProps } from './utils'

const basicList = Bem('BasicList')

export const BasicList = ({
  list,
  classNames,
  variant,
  title,
  nestedLevel,
}: BasicListProps) => {
  const nested = nestedLevel >= 1
  const nestedTree = nestedLevel >= 3
  const limited = list.length < 10

  const renderList = () => {
    const itemList = list.map((item, index) => {
      if (typeof item === 'string' || isValidElement(item))
        return (
          <li key={index}>
            <div className={basicList('ListItem', { variant, nested })}>
              <Icon
                className={basicList('ListIcon', {
                  variant,
                  nested,
                })}
                icon="Circle"
              />
              <div className={basicList('Item')}>{item}</div>
            </div>
          </li>
        )

      if (isValidListProps(item)) {
        return (
          <li data-testid="children-list" className="children-list" key={index}>
            <BasicList {...item} nestedLevel={nestedLevel + 1} />
          </li>
        )
      }
      return null
    })
    switch (variant) {
      case 'ordered':
        return (
          <ol
            data-testid="basic-list-ordered"
            className={basicList(
              'OrderedList',
              {
                nestedLevel: String(nestedLevel),
                nested,
                nestedTree,
                noneStyle: classNames === 'body-none-style',
              },
              [classNames],
            )}
          >
            {itemList}
          </ol>
        )
      case 'unordered':
        return (
          <ul
            data-testid="basic-list-unordered"
            className={basicList('UnorderedList', { nested }, [classNames])}
          >
            {itemList}
          </ul>
        )
    }
  }

  return (
    <div
      data-component="basic-list"
      className={basicList({
        nestedLevel: String(nestedLevel),
        variant,
        limited,
      })}
    >
      {title && (
        <div>
          <div
            data-testid="basic-list-title"
            className={basicList('Title', { variant })}
          >
            <Icon
              className={basicList('TitleIcon', { variant })}
              icon="Circle"
            />
            <p
              className={basicList('TitleText')}
              dangerouslySetInnerHTML={{ __html: renderMarkdown(title || '') }}
            />
          </div>
        </div>
      )}
      {renderList()}
    </div>
  )
}
