export interface OneTrustCookieBannerProps {
  siteId: string
}

export interface OneTrustConsentCookie {
  AwaitingReconsent: string
  consentId: string
  datestamp: string
  geolocation: string
  groups: string
  hosts: string
  interactionCount: string
  isGpcEnabled: string
  isIABGlobal: string
  landingPath: string
  version: string
}

export interface ConsentGroups {
  necessary: boolean
  performance: boolean
  functional: boolean
  targeting: boolean
}

export enum OneTrustConsentGroup {
  NECESSARY_COOKIES = 'C0001',
  PERFORMANCE_COOKIES = 'C0002',
  FUNCTIONAL_COOKIES = 'C0003',
  TARGETING_COOKIES = 'C0004',
}
