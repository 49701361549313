// @ts-nocheck
import { IconProps } from '../../type'
import ArrowDown from '../common/ArrowDown.svg'
import ArrowUp from '../common/ArrowUp.svg'
import Asterisk from '../common/Asterisk.svg'
import Circle from '../common/Circle.svg'
import Filter from '../common/Filter.svg'
import Sort from '../common/Sort.svg'
import SortAscending from '../common/SortAscending.svg'
import SortDescending from '../common/SortDescending.svg'
import ArrowLeft from './ArrowLeft.svg'
import ArrowRight from './ArrowRight.svg'
import Bars from './Bars.svg'
import ChalkBoard from './ChalkBoard.svg'
import Checkmark from './Checkmark.svg'
import ChevronDown from './ChevronDown.svg'
import ChevronLeft from './ChevronLeft.svg'
import ChevronRight from './ChevronRight.svg'
import ChevronUp from './ChevronUp.svg'
import Clock from './Clock.svg'
import Close from './Close.svg'
import ClosingQuote from './ClosingQuote.svg'
import Date from './Date.svg'
import Document from './Document.svg'
import Download from './Download.svg'
import Email from './Email.svg'
import ExternalLink from './ExternalLink.svg'
import Facebook from './Facebook.svg'
import FourSquares from './FourSquares.svg'
import GoogleNews from './GoogleNews.svg'
import Images from './Images.svg'
import Instagram from './Instagram.svg'
import Linkedin from './Linkedin.svg'
import Microphone from './Microphone.svg'
import News from './News.svg'
import OpeningQuote from './OpeningQuote.svg'
import Play2 from './Play2.svg'
import Play from './Play.svg'
import Precipitation from './Precipitation.svg'
import Printer from './Printer.svg'
import RSS from './RSS.svg'
import Reddit from './Reddit.svg'
import Search from './Search.svg'
import StopWatch from './StopWatch.svg'
import Trending from './Trending.svg'
import Twitter from './Twitter.svg'
import User from './User.svg'
import Watch from './Watch.svg'
import Whatsapp from './Whatsapp.svg'
import Wind from './Wind.svg'
import Youtube from './Youtube.svg'

export const theme2Icons: Record<IconProps['icon'], string> = {
  Asterisk,
  Filter,
  Sort,
  SortAscending,
  SortDescending,
  ArrowLeft,
  ArrowRight,
  Bars,
  ChalkBoard,
  Checkmark,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Circle,
  Clock,
  Close,
  ClosingQuote,
  Date,
  Document,
  Download,
  Email,
  Facebook,
  FourSquares,
  Images,
  Instagram,
  Linkedin,
  Microphone,
  News,
  OpeningQuote,
  Play2,
  Play,
  Reddit,
  RSS,
  Search,
  StopWatch,
  Trending,
  Twitter,
  GoogleNews,
  User,
  Watch,
  Whatsapp,
  Youtube,
  Precipitation,
  Wind,
  ArrowUp,
  ArrowDown,
  ExternalLink,
  Printer,
}
