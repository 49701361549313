import { PropsWithChildren } from 'react'

import { Body } from './body'
import { Cell } from './cell'
import { Head } from './head'
import { HeadSticky } from './headSticky'
import { HeaderCell } from './headerCell'
import { Row } from './row'
import { table } from './table.constants'
import { TableProps, TableType } from './type'

export const Table = (({
  className,
  children,
  headerOffset,
  ...otherProps
}: PropsWithChildren<TableProps>) => {
  return (
    <div className={table('ScrollableWrapper')}>
      <table
        data-component="table"
        className={table({}, [className])}
        {...(headerOffset ? { style: { marginTop: -headerOffset } } : {})}
        {...otherProps}
      >
        {children}
      </table>
    </div>
  )
}) as TableType

Table.Row = Row
Table.Cell = Cell
Table.HeaderCell = HeaderCell
Table.Head = Head
Table.HeadSticky = HeadSticky
Table.Body = Body
