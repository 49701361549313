import { ImageExtendedProps, ImagePreloadProps } from './type'

export const getImageSrc = ({
  src,
  width,
  format,
  disabledUpscale,
  quality,
  blur,
}: ImageExtendedProps) => {
  if (!src) return ''
  try {
    const url = new URL(src)
    url.searchParams.set('width', String(width))
    url.searchParams.set('auto', 'webp')
    url.searchParams.set('quality', String(quality))
    if (format) {
      url.searchParams.set('format', format)
    }

    if (disabledUpscale) {
      url.searchParams.set('disable', 'upscale')
    }

    if (blur) {
      url.searchParams.set('blur', String(blur))
    }

    return url.toString()
  } catch (_err: any) {
    return ''
  }
}

export const getImagesToPreload = ({
  urls,
  options = {
    width: 700,
    format: '',
  },
}: ImagePreloadProps) => {
  const imagesToPreload: Record<string, string>[] = []

  if (typeof urls === 'undefined') return []

  urls?.forEach(url => {
    const imageSrc = getImageSrc({
      src: url,
      width: options.width,
      disabledUpscale: true,
      quality: 10,
      blur: 40,
      format: options.format,
    })

    if (imageSrc) {
      imagesToPreload.push({ rel: 'preload', href: imageSrc, as: 'image' })
    }
  })

  return imagesToPreload
}
