import * as actions from './actions'
import * as guards from './guards'
import { adsModel } from './model'

export const url = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'

/*
  The machine starts in the unblocked state
  if there are ad blockers it doesn't even try to add ads

  For an ad slot to be loaded it needs to have some targeting info
  which we use the UpdateMetadata component for

  When the metadata changes the slot name, it means we need to delete all slots
  and recreate them again. For the gtag console to show the correct number of
  ad slots in the page (Requirement from the Ads team) we need to:
    - Destroy all slots
    - Un assign `window.googletag`
    - Fetch the ad script and instantiate it again
 */
export const adsMachine = (initial = 'checkingBlocked') =>
  /** @xstate-layout N4IgpgJg5mDOIC5QEMKwHQCMA2B7AxgNaQDEqsAqgHY4HESKgAOusAlgC5u5WMgAeiAIwAOAGzoxAZikBWIQAYATAE4VSqQBYxSgDQgAnojEB2KellSlYhbJGqhJ2bIC+L-eXQBXGniKk8VABBBiQQFnYuHj5BBFVzFSElWRVnJSExWSUTfSMETUd0JWSpEREhLIUnZLcPNG9fOlIIOA4AJ1wDEL4Izm5eMNjMpXQRTSslBQzMkxFcxAKTIpKyisnq2pBPH1p-CBIvJghkDjAAFWQ2mC4qKB7WPujB43t0KtNrJW05ERV5hDE43QmmS6lMcnS2Tc7hAVFwLXgYU8u3o90i-RiCz0hkQJmUwJUTkSQnGKjEKikm22jT2aMeA1AsRB5gUqQpllZJk0sjE-zEr3k41E1nGslmVLQdKiDIEwjmOIQWk0RU0IlKJlEiTksk00JcQA */
  adsModel.createMachine(
    {
      preserveActionOrder: true,
      predictableActionArguments: true,
      id: 'ads',
      initial,
      states: {
        checkingBlocked: {
          on: {
            adsUnblocked: [
              {
                target: '#ads.unblocked.waitForInitialTargeting',
                cond: 'shouldLoadWelcomeAd',
              },
              {
                target: '#ads.unblocked.waitForInitialTargeting',
                cond: 'shouldLoadBigskyAd',
              },
              {
                target: '#ads.unblocked.waitForInitialTargeting',
                cond: 'BigskyAndWelcomeAdsDisabled',
              },
              {
                target: '#ads.unblocked.loaded',
                cond: 'gtagIsLoaded',
              },
              {
                target: '#ads.unblocked.loading',
              },
            ],
            adsBlocked: '#ads.blocked',
          },
        },
        blocked: {
          type: 'final',
        },
        unblocked: {
          initial: 'loaded',
          entry: ['assignGtag', 'initGtag', 'reopenConsole'],
          on: {
            destroyAd: {
              actions: ['assignDestroySlot', 'destroySlot'],
              cond: 'canDestroyAd',
            },
          },
          states: {
            waitForInitialTargeting: {
              entry: ['assignGtag', 'initGtag', 'reopenConsole'],
              on: {
                updateTargeting: {
                  target: '#ads.unblocked',
                  actions: ['assignTargeting', 'pageTargeting'],
                },
                // Very important to understand that the we only show the welcome ad
                // If the state is "waitForInitialTargeting" and an event "showWelcomeAds" is fired
                // Which means we only want to show it only one time on the page load
                showWelcomeAds: {
                  actions: ['assignTargeting', 'showWelcomeAds'],
                  cond: 'canShowWelcomeAds',
                },
                showBigskyAd: {
                  actions: ['assignTargeting', 'showBigskyAd'],
                  cond: 'canShowBigskyAd',
                },
              },
            },
            loaded: {
              on: {
                loadAd: {
                  actions: ['assignNewSlot', 'initialiseSlot'],
                  cond: 'canLoadAd',
                },
                updateTargeting: [
                  {
                    target: '#ads.unblocked.loading',
                    actions: ['assignTargeting', 'pageTargeting'],
                    cond: 'adSlotPatternChanged',
                  },
                  {
                    actions: ['assignTargeting', 'refreshSlots'],
                    cond: 'canUpdateTargeting',
                  },
                ],
              },
            },
            loading: {
              entry: [
                'assignResetGtagAndSlots',
                'destroyAllSlotsAndDeleteWindowGtagReference',
              ],
              invoke: {
                src: 'reloadGoogleTagScript',
                onDone: [
                  {
                    target: '#ads.unblocked.waitForInitialTargeting',
                    cond: 'shouldLoadWelcomeAd',
                  },
                  {
                    target: '#ads.unblocked.waitForInitialTargeting',
                    cond: 'shouldLoadBigskyAd',
                  },
                  {
                    target: '#ads.unblocked.loaded',
                    cond: 'gtagIsLoaded',
                  },
                ],
                onError: '#ads.unblocked.failed',
              },
            },
            failed: {},
          },
        },
      },
    },
    {
      // @ts-expect-error
      actions,
      // @ts-expect-error
      guards,
      services: {
        reloadGoogleTagScript: async () => {
          return new Promise((resolve, reject) => {
            const script = document.createElement('script')
            script.src = url

            script.addEventListener('load', () => resolve(null))
            script.addEventListener('error', error => reject(error))

            document.body.appendChild(script)
          })
        },
      },
    },
  )
