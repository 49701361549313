import { Ref, FC, useEffect, useRef, useState } from 'react'

import { Bem } from '@scm/ui-core'

import { adsList } from './adsList.constants'
import {
  useAdsSend,
  useHasAdBlocker,
  useIsAdEnabled,
  useIsReadyToLoadAds,
} from './hooks'
import { adsModel } from './model'
import { AdPos, LoadAdPayload, SlotInfo } from './types'

export interface AdsProps {
  pos: AdPos
  className?: string
  sizes?: SlotInfo['sizes']
  responsiveSizeMapping?: SlotInfo['responsiveSizeMapping']
}

const ad = Bem('Ad')

export const Ad: FC<AdsProps> = props => {
  const ad = adsList[props.pos]
  const isAdEnabled = useIsAdEnabled(props.pos)
  const hasAdBlocker = useHasAdBlocker()

  if (!ad || !isAdEnabled || hasAdBlocker) return null
  return (
    <AdElement
      pos={props.pos}
      className={props.className}
      sizes={props.sizes || ad.sizes}
      responsiveSizeMapping={
        props.responsiveSizeMapping || ad.responsiveSizeMapping || []
      }
      isLazyLoaded={ad.isLazyLoaded}
      outOfPage={ad.outOfPage}
    />
  )
}

interface AdElementProps extends LoadAdPayload {
  isLazyLoaded: boolean
  className?: string
}

const AdElement = ({
  pos,
  sizes,
  responsiveSizeMapping,
  isLazyLoaded,
  className,
  outOfPage,
}: AdElementProps) => {
  const { isVisible, ref } = useIsLazyVisible(isLazyLoaded)
  useDestroyAd(pos)
  useLoadAd({ pos, sizes, responsiveSizeMapping, outOfPage }, isVisible)
  // Minimises layout shift
  const minHeight = useMinHeight(sizes)

  return pos === 'logo_1v' || pos === 'resource_v' ? (
    <div ref={ref}>
      <div
        id={pos}
        data-testid={'nested_ad_' + pos}
        className={className ? ad({}, [className]) : ad({ pos })}
        style={{ minHeight }}
      />
    </div>
  ) : (
    <div
      id={pos}
      data-testid={'ad_' + pos}
      className={className ? ad({}, [className]) : ad({ pos })}
      ref={ref}
      style={{ minHeight }}
    />
  )
}

const useIsLazyVisible = (
  isLazyLoaded: boolean,
): { isVisible: boolean; ref: Ref<HTMLDivElement> } => {
  const ref = useRef<HTMLDivElement>(null)
  const [isVisible, setState] = useState(!isLazyLoaded)

  useEffect(() => {
    if (isLazyLoaded && ref.current) {
      if (typeof IntersectionObserver === 'undefined') {
        setState(true)
        return
      }
      const element = ref.current

      const observer = new IntersectionObserver(([entry]) => {
        setState(entry.isIntersecting)
      })

      observer.observe(element)

      return () => {
        observer.unobserve(element)
      }
    }
  }, [isLazyLoaded])

  return { isVisible, ref }
}

const useLoadAd = (payload: LoadAdPayload, isVisible: boolean) => {
  const send = useAdsSend()
  const isReadyToLoadAds = useIsReadyToLoadAds()

  useEffect(() => {
    if (isVisible && isReadyToLoadAds) {
      send(adsModel.events.loadAd(payload))
    }
    // eslint-disable-next-line
  }, [send, payload.pos, isVisible, isReadyToLoadAds])
}

const useDestroyAd = (pos: AdPos) => {
  const send = useAdsSend()

  useEffect(() => () => void send(adsModel.events.destroyAd(pos)), [send, pos])
}

const useMinHeight = (sizes: AdElementProps['sizes']): string | undefined => {
  const minSizeHeight = Math.min(
    ...sizes.map(([_width, height]) =>
      height === 'fluid' ? 10 : parseInt(String(height), 10),
    ),
  )

  return isNaN(minSizeHeight) ? undefined : `${minSizeHeight}px`
}
