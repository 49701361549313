// set up IAS pet.js
const __iasPET = (typeof window !== 'undefined' && window.__iasPET) || {}
__iasPET.queue = __iasPET.queue || []
const PUBLISHER_ID = '931618'
__iasPET.pubId = PUBLISHER_ID

const __iasAdRefreshConfig =
  (typeof window !== 'undefined' && window.__iasAdRefreshConfig) || {}
__iasAdRefreshConfig.excludeList = __iasAdRefreshConfig.excludeList || {}
__iasAdRefreshConfig.excludeList.adSize = ['1x1', '5x5']
__iasAdRefreshConfig.excludeList.adSlotId = []

// include this script after you've defined all GPT ad slots
export const sendDataToIasPet = (requestAds: () => void) => {
  // requestAds is a function that is used to request ads from DFP (GAM)

  // this is the maximum amount of time in milliseconds to wait
  // for a PET response before requesting ads without PET data.
  // IAS recommends starting at 2 seconds
  // when testing and adjusting downwards as appropriate.
  const IASPET_TIMEOUT = 2000
  const __iasPETTimeoutRequestAds = setTimeout(requestAds, IASPET_TIMEOUT)

  // this function is called when a PET response is received.
  // it sets the targeting data for DFP (GAM) and request ads
  const iasDataHandler = function () {
    clearTimeout(__iasPETTimeoutRequestAds)
    __iasPET.setTargetingForGPT()
    requestAds()
  }

  // make the PET request
  googletag.cmd.push(function () {
    // read the currently defined GPT ad slots for sending to the PET endpoint
    // defined allGPT ad slots before calling PET
    const gptSlots = googletag.pubads().getSlots()
    const iasPETSlots = []
    for (let i = 0; i < gptSlots.length; i++) {
      const sizes = gptSlots[i].getSizes().map(function (size) {
        // @ts-ignore
        if (size.getWidth && size.getHeight) {
          // @ts-ignore
          return [size.getWidth(), size.getHeight()]
        }

        return [1, 1]
      })
      const slotElementId = gptSlots[i].getSlotElementId()
      iasPETSlots.push({
        adSlotId: slotElementId,
        // size: can either be a single size (for example, [728, 90])
        // or an array of sizes (for example, [[728, 90], [970, 90]])
        size: sizes,
        adUnitPath: gptSlots[i].getAdUnitPath(),
      })

      // PET JS : add ad slot id into the exclude list of ad refresh config
      if (
        gptSlots[i].getOutOfPage() &&
        !__iasAdRefreshConfig.excludeList.adSlotId.includes(slotElementId)
      ) {
        __iasAdRefreshConfig.excludeList.adSlotId.push(slotElementId)
      }
    }

    // make the request to PET. if your page makes multiple ad requests to DFP (GAM)
    // (for example, lazily loaded ads, infinite scrolling pages, etc.), make
    // a request to PET before every request to DFP (GAM)
    __iasPET.queue.push({
      adSlots: iasPETSlots,
      dataHandler: iasDataHandler,
    })
  })
}
