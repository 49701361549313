import { ScmEmbedAttrs } from '../type'

export const Ycharts = (attrs: ScmEmbedAttrs) => {
  const { src, id, title } = attrs

  if (!id) {
    return <p>{`Error: Ycharts embed must have non-empty "id" attribute`}</p>
  }

  const chart = (
    <img
      src={`https://media.ycharts.com/charts/${id}.png`}
      alt={title || 'Ycharts embed'}
    />
  )

  if (!src) {
    return chart
  }

  return (
    <a href={src} target="_blank" rel="noopener noreferrer">
      {chart}
    </a>
  )
}
