import { Content } from '@scm/ui-module-content'

export const ErrorBoundary = () => {
  return (
    <div className="ErrorBoundary">
      <Content
        content={[
          {
            type: 'heading',
            attrs: { textAlign: 'center', level: 1 },
            content: [{ type: 'text', text: '404 Error' }],
          },
          {
            type: 'paragraph',
            attrs: { textAlign: 'center' },
            content: [
              {
                type: 'text',
                marks: [{ type: 'bold' }],
                text: 'Going the wrong way?',
              },
            ],
          },
          {
            type: 'paragraph',
            attrs: { textAlign: 'center' },
            content: [
              {
                type: 'text',
                text: 'Sorry. The page you requested cannot be found. ',
              },
              {
                type: 'text',
                marks: [
                  { type: 'link', attrs: { href: '/', target: '_self' } },
                ],
                text: 'Back to home page',
              },
            ],
          },
        ]}
      />
    </div>
  )
}
