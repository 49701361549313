import { Theme } from '@scm/ui-core'

import { theme1Icons } from '../src/icons/theme1'
import { theme2Icons } from '../src/icons/theme2'
import { IconProps } from './type'
import { selectors } from '@scm/redux-store'

export const Icon = ({ className, icon, ...otherProps }: IconProps) => {
  const iconUrl = useIconUrl(icon)

  if (!iconUrl) return <span data-component="icon" data-name={icon} />

  return (
    <span
      data-component="icon"
      data-name={icon}
      className={
        className?.includes('text')
          ? className
              .split(' ')
              .filter(c => !c.includes('bg-'))
              .join(' ')
              .replace(/'text-'/g, 'bg-')
          : className
      }
      style={{
        maskImage: `url("${iconUrl}")`,
        WebkitMaskImage: `url("${iconUrl}")`,
        maskRepeat: 'no-repeat',
        WebkitMaskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        WebkitMaskSize: 'contain',
      }}
      {...otherProps}
    />
  )
}

const useIconUrl = (icon: IconProps['icon']) => {
  const { theme } = selectors.useConfig()

  switch (theme) {
    case Theme.THEME_1:
      return theme1Icons[icon]
    case Theme.THEME_2:
      return theme2Icons[icon]
    default:
      return null
  }
}
