import camelCase from 'lodash/camelCase'
import { FC } from 'react'
import { Link } from '@scm/ui-component-link'

import { Bem, checkIfTradepubLink, isInsideBrand } from '@scm/ui-core'

import { ContentImage } from './contentImage'
import {
  ContentTextComponentProps,
  ContentText as ContentTextProps,
  Marks,
  TagName,
  TypographyVariants,
  getTagName,
} from './type'
import { getClassNameModificators, getMarksByType } from './utils'

const elementMapper: Record<TypographyVariants, string> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  'body-normal': 'p',
  'body-none-style': 'p',
  'content-table': 'p',
}

const contentText = Bem('ContentText')

export const ContentText: FC<ContentTextComponentProps> = ({
  content = [],
  variant = 'body-normal',
  align,
  isParagragh,
  isSponsored,
  trackGtmClick,
}) => {
  const inner = content.map((t, i) => {
    switch (t.type) {
      case 'hardBreak':
        return <br key={i} />
      case 'text':
        return (
          <BodyTextChunk
            isSponsored={isSponsored}
            key={i}
            chunk={t}
            trackGtmClick={trackGtmClick}
          />
        )
      case 'image':
        return <ContentImage isSponsored={isSponsored} content={t} index={i} />
      default:
        return <></>
    }
  })

  const Element = elementMapper[variant] as keyof JSX.IntrinsicElements
  let idValue = undefined
  if (content[0] && 'text' in content[0]) {
    idValue = content[0].text
  }
  return (
    <>
      {isParagragh ? (
        <span
          className={contentText({ variant: camelCase(variant) })}
          data-testid="content-text"
        >
          {inner}
        </span>
      ) : (
        <Element
          className={contentText({
            variant: camelCase(variant),
            ...(align && { align }),
          })}
          data-testid="content-text"
          id={idValue}
          style={{
            scrollMarginTop: '10rem',
            scrollSnapMarginTop: '10rem',
          }}
        >
          {inner}
        </Element>
      )}
    </>
  )
}

// eslint-disable-next-line complexity
const BodyTextChunk = ({
  chunk,
  isSponsored,
  trackGtmClick,
}: {
  chunk: ContentTextProps
  isSponsored?: boolean
  trackGtmClick?: (href: string) => void
}) => {
  const marks: Marks =
    chunk.type === 'text' && Array.isArray(chunk.marks) ? chunk.marks : []
  const hasMarks = marks.length > 0
  const [link, sub, sup] = getMarksByType(
    ['link', 'subscript', 'superscript'],
    marks,
  )

  const attrs: {
    href?: string
    target?: string
    rel?: string
  } = link ? link.attrs : { rel: undefined }

  if (link?.attrs) {
    const href = link?.attrs.href || ''
    isSponsored = isSponsored && !isInsideBrand(href)
    const isTradepubLink = checkIfTradepubLink(href)
    const linkTypes = {
      isRelativeLink: href.startsWith('/'),
      isMailtoLink: href.startsWith('mailto:'),
      isStartsWithHttp: href.startsWith('http'),
      isJumpLink: href.startsWith('#'),
      isTelLink: href.startsWith('tel:'),
    }

    if (!Object.values(linkTypes).some(type => type) && !isTradepubLink) {
      attrs.href = `http://${href}`
    }
    if (isSponsored && isTradepubLink) {
      attrs.rel = 'sponsored nofollow'
    } else if (isSponsored) {
      attrs.rel = 'sponsored'
    } else if (isTradepubLink) {
      attrs.rel = 'nofollow'
    }
  }

  if (!hasMarks) return <>{chunk.text}</>

  const marksType = (link || sub || sup)?.type

  const TagName: TagName = getTagName(marksType)

  return (
    <>
      {link ? (
        <Link
          className={contentText('BodyTextChunk', {
            ...getClassNameModificators(marks),
          })}
          to={link.attrs.href}
          rel={link.attrs.rel}
          target={link.attrs.rel}
          trackGtmClick={trackGtmClick}
        >
          {chunk.text}
        </Link>
      ) : (
        <TagName
          {...attrs}
          className={contentText('BodyTextChunk', {
            ...getClassNameModificators(marks),
          })}
        >
          {chunk.text}
        </TagName>
      )}
    </>
  )
}
