import React from 'react'

import { table } from './table.constants'
import { ColProps } from './type'

export const HeaderCell = ({
  className,
  children,
  rowSpan,
  colSpan,
  ...otherProps
}: React.PropsWithChildren<ColProps>) => {
  if (rowSpan === 0 || colSpan === 0) {
    return null
  }

  return (
    <th
      className={table('HeaderCell', [className])}
      rowSpan={rowSpan}
      colSpan={colSpan}
      {...otherProps}
    >
      {children}
    </th>
  )
}
