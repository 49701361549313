import React from 'react'

import { table } from './table.constants'
import { SectionProps } from './type'

export const Head = ({
  className,
  children,
  ...otherProps
}: React.PropsWithChildren<SectionProps>) => {
  return (
    <thead className={table('Head', [className])} {...otherProps}>
      {children}
    </thead>
  )
}
