import isEqual from 'lodash/isEqual'

import { clearAd } from './actions'
import { isAdEnabled } from './hooks'
import { adsModel } from './model'
import { AdsContext } from './types'

declare const window: any

export const gtagIsLoaded = (context: AdsContext) => {
  if (typeof window === 'undefined') return false
  return Boolean(context.gtag || window.googletag)
}

// Cache the result so that we only check this once
let loadWelcomeAd: boolean | null = null
export const shouldLoadWelcomeAd = (context: AdsContext) => {
  if (loadWelcomeAd === null) {
    loadWelcomeAd = !context.welcAdOff && !context.disableWelcomeAd
  }

  return gtagIsLoaded(context) && loadWelcomeAd
}

let loadBigskyAd: boolean | null = null
export const shouldLoadBigskyAd = (context: AdsContext) => {
  if (loadBigskyAd === null) {
    loadBigskyAd = !context.disableBigskyAd
  }

  return gtagIsLoaded(context) && loadBigskyAd
}

export const BigskyAndWelcomeAdsDisabled = (context: AdsContext) => {
  return (
    gtagIsLoaded(context) &&
    (context.disableWelcomeAd || context.welcAdOff) &&
    context.disableBigskyAd
  )
}

export const canUpdateTargeting = (
  context: AdsContext,
  { targeting }: { targeting: AdsContext['targeting'] },
) => gtagIsLoaded(context) && !isEqual(context.targeting, targeting)

export const adSlotPatternChanged = (
  context: AdsContext,
  { targeting }: { targeting: AdsContext['targeting'] },
) => {
  const isChanged =
    gtagIsLoaded(context) && context.targeting?.pattern !== targeting?.pattern
  // check if welcome ad is enabled so we can hide the content on page change and load it again
  // const canShowWelcomeAd =
  //   isChanged &&
  //   isAdEnabled(context, 'welcome_v') &&
  //   !context.disableAds &&
  //   !context.disableWelcomeAd &&
  //   !context.welcAdOff
  // if (canShowWelcomeAd) {
  // document.body.classList.add('gptHideContent')
  // }
  const canShowWallpaperAd = isChanged && isAdEnabled(context, 'wallpaper_v')
  if (canShowWallpaperAd) clearAd.wallPaper()

  return isChanged
}

export const canLoadAd = (
  context: AdsContext,
  { pos }: ReturnType<typeof adsModel.events.loadAd>,
) =>
  gtagIsLoaded(context) &&
  Boolean(context.targeting) &&
  isAdEnabled(context, pos) &&
  !context.slots[pos]

export const canDestroyAd = (
  context: AdsContext,
  { pos }: ReturnType<typeof adsModel.events.destroyAd>,
) => gtagIsLoaded(context) && context.slots[pos]

export const canShowWelcomeAds = (context: AdsContext) => {
  if (loadWelcomeAd === null) {
    loadWelcomeAd = !context.welcAdOff && !context.disableWelcomeAd
  }

  return (
    gtagIsLoaded(context) && isAdEnabled(context, 'welcome_v') && loadWelcomeAd
  )
}

export const canShowBigskyAd = (context: AdsContext) => {
  if (loadBigskyAd === null) {
    loadBigskyAd = !context.disableBigskyAd
  }
  // @ts-ignore
  return (
    gtagIsLoaded(context) && isAdEnabled(context, 'bigsky_v') && loadBigskyAd
  )
}

export const purgeCache = () => {
  loadWelcomeAd = null
  loadBigskyAd = null
}
