import { AdPos, SidebarAdsListType, AdInfo } from './types'

export const dfpLogParam = 'dfp_log'

const leaderboard: Omit<AdInfo, 'pos'> = {
  isLazyLoaded: true,
  sizes: [[300, 50], [320, 50], [600, 250], [728, 90], [970, 90], 'fluid'],
  responsiveSizeMapping: [
    {
      width: 0,
      height: 0,
      sizes: [
        [300, 50],
        [320, 50],
      ],
    },
    {
      width: 750,
      height: 200,
      sizes: [[728, 90]],
    },
    {
      width: 1000,
      height: 250,
      sizes: [[600, 250], [728, 90], [970, 90], 'fluid'],
    },
  ],
}

const rectangle: Omit<AdInfo, 'pos'> = {
  isLazyLoaded: true,
  sizes: [
    [300, 100],
    [300, 250],
    [300, 600],
  ],
  responsiveSizeMapping: [
    {
      width: 0,
      height: 0,
      sizes: [
        [300, 100],
        [300, 250],
      ],
    },
    {
      width: 480,
      height: 200,
      sizes: [
        [300, 100],
        [300, 250],
        [300, 600],
      ],
    },
  ],
}

const inline: Omit<AdInfo, 'pos'> = {
  isLazyLoaded: true,
  sizes: [[300, 100], [300, 250], [600, 250], 'fluid'],
  responsiveSizeMapping: [
    {
      width: 0,
      height: 0,
      sizes: [[300, 100], [300, 250], 'fluid'],
    },
    {
      width: 744,
      height: 200,
      sizes: [[300, 100], [300, 250], [600, 250], 'fluid'],
    },
  ],
}

export const getAdsList = (): SidebarAdsListType => ({
  column: [
    'native_vertical_1v',
    'native_vertical_2v',
    'native_vertical_3v',
    'native_vertical_4v',
    'native_vertical_5v',
    'native_vertical_6v',
    'native_vertical_7v',
    'native_vertical_8v',
    'native_vertical_9v',
    'native_vertical_10v',
  ],
  default: [
    'native_right_1v',
    'native_right_2v',
    'native_right_4v',
    'native_right_5v',
    'native_right_6v',
    'native_right_7v',
    'native_right_8v',
    'native_right_9v',
    'native_right_10v',
  ],
})

export const getAdsBreakpoint = (pos: AdPos | null) => {
  if (pos && adsList?.[pos]?.breakpoint) return adsList[pos].breakpoint
  return null
}

const rectangleArticle: Omit<AdInfo, 'pos'> = {
  isLazyLoaded: true,
  sizes: [
    [300, 250],
    [300, 100],
  ],
  responsiveSizeMapping: [],
}

const sponsorLogo: Omit<AdInfo, 'pos'> = {
  isLazyLoaded: false,
  sizes: [[100, 40]],
  responsiveSizeMapping: [],
}

const nativeAd: Omit<AdInfo, 'pos'> = {
  isLazyLoaded: true,
  sizes: ['fluid'],
  responsiveSizeMapping: [],
}

export const adsList: Record<AdPos, AdInfo> = {
  '728_1v': {
    ...leaderboard,
    sizes: [...leaderboard.sizes, [970, 250], [1, 1]],
    responsiveSizeMapping: [
      {
        ...leaderboard.responsiveSizeMapping[0],
        sizes: [...leaderboard.responsiveSizeMapping[0].sizes, [1, 1]],
      },
      {
        ...leaderboard.responsiveSizeMapping[1],
        sizes: [...leaderboard.responsiveSizeMapping[1].sizes, [1, 1]],
      },
      {
        ...leaderboard.responsiveSizeMapping[2],
        sizes: [
          ...leaderboard.responsiveSizeMapping[2].sizes,
          [970, 250],
          [1, 1],
        ],
      },
    ],
  },
  '728_2v': leaderboard,
  '728_3v': leaderboard,
  '728_4v': leaderboard,
  '728_5v': leaderboard,
  '728_6v': leaderboard,
  '728_7v': leaderboard,
  '728_8v': leaderboard,
  '728_9v': leaderboard,
  '728_10v': leaderboard,
  '728_11v': leaderboard,
  '728_12v': leaderboard,
  '728_13v': leaderboard,
  '728_14v': leaderboard,
  '728_15v': leaderboard,
  '728_16v': leaderboard,
  inline_1v: inline,
  inline_2v: inline,
  slide_728_1v: leaderboard,
  '300_1v': rectangle,
  '300_2v': rectangle,
  '300_3v': rectangle,
  '300_4v': rectangle,
  '300_5v': rectangle,
  '300_6v': rectangle,
  '300_7v': rectangle,
  '300_8v': rectangle,
  '300_9v': rectangle,
  '300_10v': rectangle,
  '300_1v_microlist': rectangle,
  slide_300_1v: rectangle,
  '300_1v_article': rectangleArticle,
  '300_2v_article': rectangleArticle,
  '300_3v_article': rectangleArticle,
  '100_1v': sponsorLogo,
  '100_2v': sponsorLogo,
  '100_3v': sponsorLogo,
  '100_4v': sponsorLogo,
  '100_5v': sponsorLogo,
  '100_6v': sponsorLogo,
  '100_7v': sponsorLogo,
  '100_8v': sponsorLogo,
  // Native Homepage
  native_featured_1v: nativeAd,
  native_latestleft_1v: nativeAd,
  native_latestleft_2v: nativeAd,
  native_latestleft_3v: nativeAd,
  native_latestleft_4v: nativeAd,
  native_latestleft_5v: nativeAd,
  native_latestleft_6v: nativeAd,
  native_latestleft_7v: nativeAd,
  native_latestleft_8v: nativeAd,
  native_latestleft_9v: nativeAd,
  native_latestleft_10v: nativeAd,
  native_latestright_1v: nativeAd,
  native_latestright_2v: nativeAd,
  native_latestright_3v: nativeAd,
  native_latestright_4v: nativeAd,
  native_latestright_5v: nativeAd,
  native_latestright_6v: nativeAd,
  native_latestright_7v: nativeAd,
  native_latestright_8v: nativeAd,
  native_latestright_9v: nativeAd,
  native_latestright_10v: nativeAd,
  native_related_1v: nativeAd,
  native_related_2v: nativeAd,
  native_related_3v: nativeAd,
  native_related_4v: nativeAd,
  native_related_5v: nativeAd,
  native_related_6v: nativeAd,
  native_related_7v: nativeAd,
  native_related_8v: nativeAd,
  native_related_9v: nativeAd,
  native_related_10v: nativeAd,
  // Native Article
  native_1v: nativeAd,
  native_2v: nativeAd,
  native_right_1v: nativeAd,
  native_right_2v: nativeAd,
  native_right_3v: nativeAd,
  native_right_4v: nativeAd,
  native_right_5v: nativeAd,
  native_right_6v: nativeAd,
  native_right_7v: nativeAd,
  native_right_8v: nativeAd,
  native_right_9v: nativeAd,
  native_right_10v: nativeAd,
  // Native Vertical
  native_vertical_1v: nativeAd,
  native_vertical_2v: nativeAd,
  native_vertical_3v: nativeAd,
  native_vertical_4v: nativeAd,
  native_vertical_5v: nativeAd,
  native_vertical_6v: nativeAd,
  native_vertical_7v: nativeAd,
  native_vertical_8v: nativeAd,
  native_vertical_9v: nativeAd,
  native_vertical_10v: nativeAd,
  // Native Grid
  native_grid_1v: nativeAd,
  native_grid_2v: nativeAd,
  native_grid_3v: nativeAd,
  native_grid_4v: nativeAd,
  // List
  native_topic_1v: nativeAd,
  native_topic_2v: nativeAd,
  native_topic_3v: nativeAd,
  native_topicright_1v: nativeAd,
  native_topicright_2v: nativeAd,
  native_topicright_3v: nativeAd,
  resource_v: {
    isLazyLoaded: true,
    sizes: [[5, 5]],
    responsiveSizeMapping: [],
  },
  welcome_v: {
    isLazyLoaded: false,
    sizes: [[1, 1]],
    responsiveSizeMapping: [],
  },
  bigsky_v: {
    isLazyLoaded: false,
    sizes: [[1, 1]],
    responsiveSizeMapping: [],
    outOfPage: false,
  },
  floor_v: {
    isLazyLoaded: false,
    sizes: [[1, 1]],
    responsiveSizeMapping: [],
  },
  adhesion_v: {
    isLazyLoaded: false,
    sizes: [[1, 1]],
    responsiveSizeMapping: [],
  },
  wallpaper_v: {
    isLazyLoaded: false,
    sizes: [],
    responsiveSizeMapping: [],
    outOfPage: true,
    breakpoint: 1440,
  },
  video_v: {
    isLazyLoaded: true,
    sizes: [[1, 1]],
    responsiveSizeMapping: [],
  },
  oop_v: {
    isLazyLoaded: false,
    sizes: [[1, 1]],
    responsiveSizeMapping: [],
    outOfPage: true,
  },
  sponsoredlogo_weather_1v: {
    isLazyLoaded: true,
    sizes: [
      [125, 125],
      [90, 90],
      [160, 65],
    ],
    responsiveSizeMapping: [],
  },
  sponsoredlogo_commodities_1v: {
    isLazyLoaded: true,
    sizes: [
      [125, 125],
      [90, 90],
      [160, 65],
    ],
    responsiveSizeMapping: [],
  },
  logo_1v: {
    isLazyLoaded: true,
    sizes: [
      [125, 125],
      [90, 90],
      [160, 65],
    ],
    responsiveSizeMapping: [],
  },
  reveal_1v: {
    isLazyLoaded: true,
    sizes: [[1, 1]],
    responsiveSizeMapping: [],
  },
  slide_interstitial_1v: {
    isLazyLoaded: false,
    sizes: [[300, 250]],
    responsiveSizeMapping: [],
  },
  slide_interstitial_2v: {
    isLazyLoaded: false,
    sizes: [[300, 250]],
    responsiveSizeMapping: [],
  },
  slide_interstitial_3v: {
    isLazyLoaded: false,
    sizes: [[300, 250]],
    responsiveSizeMapping: [],
  },
  slide_interstitial_4v: {
    isLazyLoaded: false,
    sizes: [[300, 250]],
    responsiveSizeMapping: [],
  },
  slide_interstitial_5v: {
    isLazyLoaded: false,
    sizes: [[300, 250]],
    responsiveSizeMapping: [],
  },
  chatbox_1v: {
    isLazyLoaded: true,
    sizes: [[1, 1]],
    responsiveSizeMapping: [],
  },
}
