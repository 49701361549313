import {
  Animoto,
  Canva,
  Ceros,
  Datawrapper,
  Everviz,
  Flywheel,
  Infogram,
  Limelight,
  Turtl,
  Wistia,
  Ycharts,
  NxtGen,
} from './embedSnippets'
import { ContentScmEmbedProps, ScmEmbedAttrs } from './type'

export const ContentScmEmbed = (item: ContentScmEmbedProps) => {
  try {
    return getEmbed(item.attrs)
  } catch (error) {
    const message = error instanceof Error ? error.message : 'No error message'
    return (
      <p>
        Error while transforming scm-embed: {item.content[0].text}. {message}
      </p>
    )
  }
}

const getEmbed = (attrs: ScmEmbedAttrs) => {
  if (!attrs.type) {
    return <p>{`Error: scm-embed should have 'type'`}</p>
  }

  // Checking for pre-existing CSS units
  const { 'padding-top': paddingTop } = attrs
  const validateCSSUnit =
    /^\d+(\.\d+)?(px|%|em|rem|pt|vw|vh|vmin|vmax|cm|mm|in)$/
  const formattedPaddingTop = paddingTop
    ? validateCSSUnit.test(paddingTop as string)
      ? paddingTop
      : `${paddingTop}%`
    : ''

  switch (attrs.type) {
    case 'animoto':
      return <Animoto {...attrs} />
    case 'ceros':
      return <Ceros {...attrs} />
    case 'datawrapper':
      return <Datawrapper {...attrs} />
    case 'infogram':
      return <Infogram {...attrs} />
    case 'canva':
      return <Canva {...attrs} formattedPaddingTop={formattedPaddingTop} />
    case 'wistia':
      return <Wistia {...attrs} formattedPaddingTop={formattedPaddingTop} />
    case 'everviz':
      return <Everviz {...attrs} />
    case 'ycharts':
      return <Ycharts {...attrs} />
    case 'turtl':
      return <Turtl {...attrs} />
    case 'limelight':
      return <Limelight {...attrs} />
    case 'flywheel':
      return <Flywheel {...attrs} />
    case 'nxtgen':
      return <NxtGen {...attrs} />
    default:
      return <p>{`Error: Unknown embed type: ${attrs.type}`}</p>
  }
}
