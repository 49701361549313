import { ScmEmbedAttrs } from '../type'

export const Animoto = (attrs: ScmEmbedAttrs) => {
  const { id: id, src, title = 'Animoto video embed' } = attrs

  if (!id || !src) {
    return (
      <p>{`Error: Animoto embeds should have non-empty "id" & "src" attributes`}</p>
    )
  }

  const ratio = 9 / 16

  return (
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        padding: `${100 * ratio}% 0 0 0`,
      }}
    >
      <iframe
        id={id}
        src={src}
        title={title}
        // eslint-disable-next-line react/no-unknown-property
        allowTransparency={true}
        allowFullScreen={true}
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          bottom: '0',
          right: '0',
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  )
}
