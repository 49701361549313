import { ScmEmbedAttrs } from '../type'

export const Flywheel = (attrs: ScmEmbedAttrs) => {
  const { src, height, title = 'FlyWheel embed' } = attrs

  if (!src || !height) {
    return (
      <p>{`Error: FlyWheel embeds should have non-empty "src" & "height" attributes`}</p>
    )
  }

  return (
    <iframe
      src={src}
      frameBorder="0"
      width="100%"
      height={`${height}px`}
      title={title}
    />
  )
}
