import { useCallback, useEffect, useRef, useState } from 'react'

import { EventType, GTM } from '@scm/tag-manager'

import { useAdsSend, useStateContext } from './hooks'
import { adsModel } from './model'

import { useWindowListener } from '@scm/event-listener'
import {
  getIsCookieBannerClosed,
  getIsTargetingCookieSet,
} from '@scm/onetrust-cookie-banner'
import { log } from './log'

declare global {
  interface WindowEventMap {
    cookieBannerConsentChanged: CustomEvent
  }
}

export const AdBlocker = () => {
  const fakeAdRef = useRef<HTMLDivElement>(null)
  const areAdsBlockedRef = useRef<boolean | null>(null)
  const send = useAdsSend()
  const { isDctsTurnOn, disableAds, disableCookieBanner } = useStateContext()
  const sendEvent = GTM.hooks.useSendEvent()
  const [adBlockDetected, setAdBlockDetected] = useState<null | boolean>(null)
  const [cookieBannerReady, setCookieBannerReady] = useState(
    disableCookieBanner ||
      getIsCookieBannerClosed() ||
      getIsTargetingCookieSet(),
  )

  useEffect(() => {
    fetch('https://www3.doubleclick.net', {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store',
    })
      .then(({ redirected }) => {
        setAdBlockDetected(redirected)
      })
      .catch(() => {
        setAdBlockDetected(true)
      })
  }, [])

  useWindowListener('cookieBannerConsentChanged', ({ detail }) => {
    log.info('cookieBannerConsentChanged listener', detail)
    setCookieBannerReady(detail?.allowPersonalised || detail?.isClosed)
  })

  const dispatchIsBlocked = useCallback((isBlocked = false) => {
    send(
      isBlocked ? adsModel.events.adsBlocked() : adsModel.events.adsUnblocked(),
    )
  }, [])

  useEffect(() => {
    if (disableAds) {
      dispatchIsBlocked(true)
    }

    if (adBlockDetected === null || !cookieBannerReady) return

    if (
      adBlockDetected ||
      (fakeAdRef.current &&
        getComputedStyle(fakeAdRef.current)['display'] === 'none')
    ) {
      areAdsBlockedRef.current = true
      dispatchIsBlocked(true)
    }

    if (isDctsTurnOn) {
      fetch('/api/isRobotIP')
        .then(result => {
          if (result.ok) {
            return result.json()
          }
          throw result.statusText
        })
        .then(({ isRobotIP }) => {
          if (!areAdsBlockedRef.current) {
            dispatchIsBlocked(isRobotIP)
          }

          if (isRobotIP) {
            sendEvent({
              event: EventType.DATA_CENTER_ADS_BLOCKED,
              user: {
                isDataCenter: true,
              },
            })
          }
        })
        .catch(_ => {
          dispatchIsBlocked()
        })
    } else if (!areAdsBlockedRef.current) {
      dispatchIsBlocked()
    }
  }, [adBlockDetected, isDctsTurnOn, disableAds, cookieBannerReady])
  return (
    <div
      ref={fakeAdRef}
      data-testid="ad_blocker"
      className="ads ad adsbox doubleclick ad-placement carbon-ads"
      style={{
        height: '1px',
        width: '1px',
        position: 'absolute',
        left: '-999em',
        top: '-999em',
      }}
    />
  )
}
