import { kebabCase, snakeCase } from 'lodash'
import { AdsMetadata, Reg, ContentIds } from './types'

import { sanitiseString } from './utils'

const getCommonTargeting = (
  metadata: Partial<ContentIds> & Reg,
  pattern: string,
  ptype: string,
) => ({
  pattern: pattern,
  ptype: ptype,
  nid: metadata.contentId,
  aid: metadata.aid,
  reg: metadata.reg,
})

const getPublicationIssueTargeting = (
  metadata: Extract<AdsMetadata, { type: 'publication-issue' }>,
) => ({
  ...getCommonTargeting(
    metadata,
    `publications/${metadata.issue}`,
    'publications',
  ),
  pterm: getPtermTargeting(metadata),
  sterm: getMultiplesTargeting(metadata, 'sterm'),
  contentFormat: sanitiseString(metadata.contentFormat || ''),
  buyersJourney: sanitiseString(metadata.buyersJourney || ''),
  contributor: getMultiplesTargeting(metadata, 'contributor'),
  gatedWithExternalForm: metadata.gatedWithExternalForm?.toString() || '',
  gatedWithSiteReg: metadata.gatedWithSiteReg?.toString() || '',
  isSponsored: metadata.isSponsored?.toString() || '',
  paidGating: metadata.paidGating?.toString() || '',
  sponsorName: sanitiseString(metadata.sponsorName || ''),
})

const getPatternWithSlug = (prefix: string, metadata: { slug?: string }) =>
  metadata.slug ? `${prefix}/${metadata.slug}` : prefix

const getPatternWithMediaCenterType = (
  mediaCenterType: string,
  slug: string,
) =>
  mediaCenterType
    ? `media-center/${mediaCenterType}/${slug}`
    : `media-center/${slug}`

export const getTargetingFromMetadata = (metadata: AdsMetadata) => {
  switch (metadata.type) {
    case 'topic':
      return {
        ...getCommonTargeting(metadata, `topic/${metadata.topic}`, 'topic'),
        pterm: sanitiseString(metadata.topic),
      }
    case 'commodities':
      return getCommonTargeting(
        metadata,
        getPatternWithSlug('commodities', metadata),
        'commodities',
      )
    case 'publication-issue':
      return getPublicationIssueTargeting(metadata)
    case 'topic-slug':
    case 'topic-tertiary':
      return getTopicSlugTargeting(metadata)
    case 'contributor':
      return {
        pattern: `contributor/${metadata.contributor}`,
        ptype: 'contributor',
        reg: metadata.reg,
      }
    case 'magazine-slug':
      return getCommonTargeting(
        metadata,
        `magazine/${metadata.slug}`,
        'magazine',
      )
    case 'keyword-slug':
      return getCommonTargeting(metadata, `keyword/${metadata.slug}`, 'keyword')
    case 'publication-slug':
      return getCommonTargeting(
        metadata,
        `publications/${kebabCase(metadata.slug)}`,
        'publication_title',
      )
    case 'event-slug':
      return getCommonTargeting(metadata, `events/${metadata.slug}`, 'events')
    case 'content-format-list':
      return {
        pattern: `${metadata.slug}-list`,
        ptype: `${metadata.slug}-list`,
        reg: metadata.reg,
      }
    case 'series': {
      const ptypeSeries = metadata.contentType
        ? `${metadata.contentType},series`
        : 'series'
      return {
        ...getCommonTargeting(metadata, `series/${metadata.slug}`, ptypeSeries),
        series: snakeCase(metadata.slug),
      }
    }
    case 'program': {
      const ptypeProgram = metadata.contentType
        ? `${metadata.contentType},program`
        : 'program'
      return {
        ...getCommonTargeting(
          metadata,
          `program/${kebabCase(metadata.slug)}`,
          ptypeProgram,
        ),
        program: metadata.programName,
        content: 'program',
      }
    }
    case 'premier-page':
      return {
        pattern: `premier-page/${kebabCase(metadata.slug)}`,
        ptype: `premier${metadata.isSponsored ? ',sponsored' : ''}`,
        premier: metadata.premierName || undefined,
        isSponsored: metadata?.isSponsored?.toString() || '',
        gatedWithSiteReg: metadata?.gatedWithSiteReg?.toString() || '',
        gatedWithExternalForm:
          metadata?.gatedWithExternalForm?.toString() || '',
        aid: metadata.aid,
        reg: metadata.reg,
        premierName: metadata?.premierName,
      }
    case 'enhanced-list-landing-page':
    case 'enhanced-list-child-page':
      return {
        pattern: `enhanced-list/${metadata.topic}`,
        ptype: 'enhanced-listing',
        reg: metadata.reg,
      }
    case 'media-center-all':
      return getCommonTargeting(
        metadata,
        `media-center/${metadata.mediaCenterType}`,
        'media-center',
      )
    case 'media-center-individual':
      return getCommonTargeting(
        metadata,
        getPatternWithMediaCenterType(
          metadata.mediaCenterType || '',
          metadata.slug,
        ),
        'media-center',
      )
    case '3-month-forecast':
      return {
        pattern: 'weather/forecast',
        ptype: 'weather/forecast',
        reg: metadata.reg,
      }
    case 'media-center':
    case 'magazine-list':
    case 'podcast-list':
    case 'webinar-list':
    case 'journal-list':
    case 'document-list':
    case 'video-list':
    case 'slideshow-list':
    case 'event-list':
    case 'search':
    case 'recipe':
    case 'homepage':
    case 'weather':
    case 'publications':
    case 'resource-library':
      return {
        pattern: metadata.type,
        ptype: metadata.type,
        reg: metadata.reg,
      }
    default:
      return null
  }
}

// eslint-disable-next-line complexity
const getTopicSlugTargeting = (metadata: AdsMetadata) => {
  if (metadata.type !== 'topic-slug' && metadata.type !== 'topic-tertiary')
    return null

  let suffix = ''

  if (metadata.type === 'topic-tertiary') {
    suffix = `/${metadata.slug}/${metadata.tertiary}`
  } else {
    suffix = metadata.contentType === 'topic' ? '/' + metadata.slug : ''
  }

  const ptype = [
    metadata.contentType,
    metadata.isSponsored ? 'sponsored' : '',
    metadata.seriesName ? 'series' : '',
    metadata.programName ? 'program' : '',
  ]
    .filter(text => Boolean(text))
    .join(',')
  const content = [
    metadata.programName ? 'program' : '',
    metadata.seriesName ? 'series' : '',
  ]
    .filter(text => Boolean(text))
    .join(',')
  const defaultTarget = {
    pattern: `${metadata.contentType}/${metadata.topic}${suffix}`,
    pterm: getPtermTargeting(metadata),
    sterm: getMultiplesTargeting(metadata, 'sterm'),
    contentFormat: sanitiseString(metadata.contentFormat || ''),
    buyersJourney: sanitiseString(metadata.buyersJourney || ''),
    contributor: getMultiplesTargeting(metadata, 'contributor'),
    gatedWithExternalForm: metadata?.gatedWithExternalForm?.toString() || '',
    gatedWithSiteReg: metadata?.gatedWithSiteReg?.toString() || '',
    isSponsored: metadata?.isSponsored?.toString() || '',
    paidGating: metadata?.paidGating?.toString() || '',
    sponsorName: sanitiseString(metadata.sponsorName || ''),
    ptype,
    nid: metadata.contentId,
    aid: metadata.aid,
    reg: metadata.reg,
    kw: metadata.kw,
    series: metadata.seriesName || undefined,
    program: metadata.programName || undefined,
    content: content || undefined,
  }

  if (metadata.isSponsored && metadata.programName) {
    return {
      ...defaultTarget,
      pattern: `program/${kebabCase(metadata.programName)}`,
    }
  }

  if (metadata.isSponsored) {
    return {
      ...defaultTarget,
      pattern: `${metadata.contentType}/sponsored/${metadata.topic}${suffix}`,
    }
  }

  if (metadata.seriesName) {
    return {
      ...defaultTarget,
      pattern: `series/${kebabCase(metadata.seriesName)}`,
    }
  }

  if (metadata.programName) {
    return {
      ...defaultTarget,
      pattern: `program/${kebabCase(metadata.programName)}`,
    }
  }

  return defaultTarget
}

// eslint-disable-next-line complexity
const getPtermTargeting = (metadata: AdsMetadata) => {
  if (
    metadata.type !== 'topic-slug' &&
    metadata.type !== 'topic-tertiary' &&
    metadata.type !== 'publication-issue'
  )
    return ''

  if (!metadata.pterm && !metadata.topic) {
    return ''
  }

  let formattedPterm = ''
  if (!metadata.pterm && metadata.topic) {
    // Topic pages
    if (metadata.type === 'topic-slug') {
      formattedPterm =
        `${sanitiseString(metadata.topic)},` +
        `${sanitiseString(metadata.slug)}`
    }
    if (metadata.type === 'topic-tertiary') {
      formattedPterm =
        `${sanitiseString(metadata.topic)},` +
        `${sanitiseString(metadata.slug)},` +
        `${sanitiseString(metadata.tertiary)}`
    }
  }
  if (metadata.pterm) {
    // Content pages
    if (
      metadata.type === 'topic-slug' ||
      metadata.type === 'publication-issue'
    ) {
      if (metadata.pterm.grandparent.title) {
        formattedPterm =
          metadata.pterm.parent && metadata.pterm.grandparent
            ? `${sanitiseString(metadata.pterm.grandparent.title)},` +
              `${sanitiseString(metadata.pterm.parent)},` +
              `${sanitiseString(metadata.pterm.main)}`
            : `${sanitiseString(metadata.pterm.main)}`
      } else {
        formattedPterm = metadata.pterm.parent
          ? `${sanitiseString(metadata.pterm.parent)},` +
            `${sanitiseString(metadata.pterm.main)}`
          : `${sanitiseString(metadata.pterm.main)}`
      }
    }
  }
  return formattedPterm
}

const getMultiplesTargeting = (metadata: AdsMetadata, term: string) => {
  if (
    metadata.type !== 'topic-slug' &&
    metadata.type !== 'topic-tertiary' &&
    metadata.type !== 'publication-issue'
  )
    return ''

  if (term === 'sterm') {
    if (!metadata.pterm?.additional) return ''

    const additionalTopics: any[] = []

    metadata.pterm.additional.forEach(({ title }: { title: string }) => {
      additionalTopics.push(sanitiseString(title))
    })

    return additionalTopics.join(',')
  }

  if (term === 'contributor') {
    if (!metadata.contributor) return ''

    const contributorNames: string[] = []

    metadata.contributor.forEach(({ name }: { name: string }) => {
      contributorNames.push(sanitiseString(name))
    })
    return contributorNames.join(',')
  }
}
