import React from 'react'

import { table } from './table.constants'
import { SectionProps } from './type'

export const Body = ({
  className,
  children,
  ...otherProps
}: React.PropsWithChildren<SectionProps>) => {
  return (
    <tbody className={table('Body', [className])} {...otherProps}>
      {children}
    </tbody>
  )
}
