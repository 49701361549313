import { useEffect } from 'react'

import { ScmEmbedAttrs } from '../type'

interface LimelightPlayerUtil {
  embed(attrs: ScmEmbedAttrs): void
}

declare const LimelightPlayerUtil: LimelightPlayerUtil
const defaultHeight = '385px'
const defaultWidth = '100%'
const defaultPlayerForm = 'LVPPlayer'

export const Limelight = (attrs: ScmEmbedAttrs) => {
  const { src, id, height, width, mediaId, playerForm } = attrs

  useEffect(() => {
    if (typeof LimelightPlayerUtil !== 'undefined') {
      LimelightPlayerUtil.embed({
        height: height || defaultHeight,
        width: width || defaultWidth,
        mediaId: mediaId,
        playerId: id,
        playerForm: playerForm || defaultPlayerForm,
      })
    } else if (!document.getElementById('limelight-async')) {
      const script = document.createElement('script')
      script.src = src || ''
      script.type = 'text/javascript'
      script.id = 'limelight-async'
      const firstScriptOnPage = document.getElementsByTagName('script')[0]
      firstScriptOnPage?.parentNode?.insertBefore(script, firstScriptOnPage)
    }
  })

  if (!src || !mediaId || !id) {
    return (
      <p>{`Error: Limelight embeds must have non-empty "src", "mediaId" and "id" attributes`}</p>
    )
  }

  return <div id={id} />
}
