/* eslint-disable no-case-declarations */
import { Image } from '@scm/ui-component-image'
import { Bem } from '@scm/ui-core'

import { ContentText } from './contentText'
import { ContentParagraphProps, IContentImage } from './type'

const paragraph = Bem('ContentParagraph')

export const ContentParagraph = ({
  content,
  attrs,
  paragraphVariant = 'body-normal',
  isSponsored,
  trackGtmClick,
}: ContentParagraphProps) => {
  return (
    <p
      className={paragraph({
        ...(attrs?.textAlign && { align: attrs?.textAlign }),
      })}
      data-testid="content-paragraph"
    >
      {content?.map((content, index) => {
        switch (content.type) {
          case 'text':
            return (
              <ContentText
                key={index}
                variant={paragraphVariant}
                content={[content]}
                isParagragh={true}
                isSponsored={isSponsored}
                trackGtmClick={trackGtmClick}
              />
            )
          case 'image':
            const image = content as IContentImage

            return (
              <>
                <Image
                  key={index}
                  fallback="brand"
                  src={image.attrs.src}
                  alt={image.attrs.alt || ''}
                  className={paragraph('Image')}
                  width={
                    image?.attrs?.style?.width
                      ? getDimension(image?.attrs?.style?.width)
                      : undefined
                  }
                />
                {image.attrs?.title && (
                  <p className={paragraph('Caption')}>{image.attrs.title}</p>
                )}
              </>
            )
          case 'hardBreak':
            return <br key={index} />
          default:
            return null
        }
      })}
    </p>
  )
}

export const getDimension = (item: string) => {
  return parseInt(item.split('px')?.[0])
}
