import { useRef } from 'react'
import type { ColumnSort, Header } from '@tanstack/react-table'
import { flexRender } from '@tanstack/react-table'

import { Icon, IconType } from '@scm/ui-component-icon'
import { Table } from '@scm/ui-component-table'
import { Bem } from '@scm/ui-core'

import { useVirtualTable } from './hooks/useVirtualTable'
import {
  ContentParagraph,
  ContentTable as ContentTableProps,
  ContentTableRow,
} from './type'
import { Content } from './content'

const contentTable = Bem('ContentTable')

export interface UseContentTableProps {
  content: ContentTableRow[]
}

const renderSortIcon = (
  header: Header<ContentParagraph[][], unknown>,
  firstSortRule: ColumnSort,
) => {
  const isSortOnThisHeader = header.id === firstSortRule?.id
  const isActive = !firstSortRule || isSortOnThisHeader

  let icon: IconType = 'Sort'

  if (isSortOnThisHeader) {
    icon = firstSortRule?.desc ? 'SortAscending' : 'SortDescending'
  }

  return (
    <Icon
      icon={icon}
      className={contentTable('SortIcon', {
        isActive,
      })}
      onClick={header.column.getToggleSortingHandler()}
    />
  )
}

const renderHeader = (
  header: Header<ContentParagraph[][], unknown>,
  firstSortRule: ColumnSort,
) => {
  const headerContent = header.column.columnDef.header
  if (!headerContent) return null

  return (
    <>
      {flexRender(headerContent, header.getContext())}
      {renderSortIcon(header, firstSortRule)}
    </>
  )
}

export const ContentTable = (props: ContentTableProps) => {
  const refTableContainer = useRef<HTMLTableElement>(null)
  const {
    isEmpty,
    hasHeader,
    hasTitle,
    tableTitle,
    hasFooter,
    tableFooter,
    sorting,
    headerGroups,
    rows,
  } = useVirtualTable(props)
  const firstSortRule = sorting[0]
  const TABLE_HEAD_OFFSET = 30
  const { trackGtmClick } = props

  if (isEmpty) return null

  return (
    <>
      {hasTitle && (
        <div className={contentTable('Title')}>
          <Content
            content={tableTitle}
            paragraphVariant="h5"
            doWrap={false}
            trackGtmClick={trackGtmClick}
          />
        </div>
      )}
      <div ref={refTableContainer}>
        <Table
          className={contentTable()}
          {...(hasHeader ? { headerOffset: TABLE_HEAD_OFFSET } : {})}
        >
          {hasHeader && (
            <Table.HeadSticky
              refTableContainer={refTableContainer}
              headerOffset={TABLE_HEAD_OFFSET}
            >
              {headerGroups.map(headerGroup => (
                <Table.Row key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <Table.HeaderCell
                      key={header.id}
                      colSpan={header.colSpan}
                      className=""
                      style={{ paddingTop: TABLE_HEAD_OFFSET }}
                    >
                      <div className={contentTable('HeaderCellWrapper')}>
                        {renderHeader(header, firstSortRule)}
                      </div>
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              ))}
            </Table.HeadSticky>
          )}
          <Table.Body>
            {rows.map((row, rowIndex) => (
              <Table.Row key={rowIndex}>
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <Table.Cell key={cellIndex}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      {hasFooter && (
        <div className={contentTable('Footer')}>
          <Content
            content={tableFooter}
            paragraphVariant="body-normal"
            doWrap={false}
            trackGtmClick={trackGtmClick}
          />
        </div>
      )}
    </>
  )
}
