import { useEffect } from 'react'

import { ScmEmbedAttrs } from '../type'

interface InfogramEmbeds {
  initialized: boolean

  process(): void
}

declare global {
  interface Window {
    InfogramEmbeds?: InfogramEmbeds
  }
}

{
  /* eslint-disable react/no-unknown-property */
}
export const Infogram = ({ id, title = 'Infogram embed' }: ScmEmbedAttrs) => {
  useEffect(() => {
    if (window.InfogramEmbeds?.initialized) {
      window.InfogramEmbeds?.process()
    } else if (!document.getElementById('infogram-async')) {
      const script = document.createElement('script')
      script.async = true
      script.id = 'infogram-async'
      script.src = 'https://e.infogram.com/js/dist/embed-loader-min.js'

      const firstScriptOnPage = document.getElementsByTagName('script')[0]
      firstScriptOnPage?.parentNode?.insertBefore(script, firstScriptOnPage)
    }
  }, [id])

  if (!id) {
    return <p>{"Error: Infogram embeds should have 'id'"}</p>
  }

  return (
    <div className="embed-container-infogram" embed-exclude="">
      <div
        className="infogram-embed"
        data-id={id}
        data-title={title}
        data-type="interactive"
        embed-exclude=""
      >
        &nbsp;
      </div>
    </div>
  )
}
