import { createContext, useContext } from 'react'

import { AnyStateMachine, InterpreterFrom } from '@scm/xstate'

import { AdBlocker } from './adBlocker'

const Context = createContext<InterpreterFrom<() => AnyStateMachine>>(
  {} as InterpreterFrom<() => AnyStateMachine>,
)

export interface AdsProviderProps {
  service: InterpreterFrom<() => AnyStateMachine>
  children?: any
}

export const AdsProvider = ({ service, children }: AdsProviderProps) => {
  return (
    <Context.Provider value={service}>
      <AdBlocker />

      <div>{children}</div>
    </Context.Provider>
  )
}

export const useAdsService = () => useContext(Context)
