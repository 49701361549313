import { refreshSlots, refreshSpecificSlots } from './actions'
import { Ad } from './ad'
import { AdsProvider } from './adsProvider'
import {
  useAreAllAdsEnabled,
  useGoogleTag,
  useIsAdActive,
  useIsAdRender,
  useStateContext,
  useTargetingWithMetaData,
  useAdsRender,
  useShowAds,
} from './hooks'
import { adsMachine, url } from './machine'
import { getTargetingFromMetadata } from './updateMetadata'
import { WelcomeAd } from './welcomeAd'

export * from './types'
export * from './utils'
export { getAdsList } from './adsList.constants'
export const Ads = {
  Provider: AdsProvider,
  machine: adsMachine,
  targeting: getTargetingFromMetadata,
  url,
  Ad,
  WelcomeAd,
  hooks: {
    useAreAllAdsEnabled,
    useIsAdActive,
    useGoogleTag,
    useIsAdRender,
    useStateContext,
    useTargetingWithMetaData,
    useAdsRender,
    useShowAds,
  },
  action: {
    refreshSlots,
    refreshSpecificSlots,
  },
}
