import * as React from 'react'
import type { SortingState } from '@tanstack/react-table'
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { UseContentTableProps } from '../contentTable'
import {
  CellComponent,
  HeaderCellComponent,
  columnHelper,
  compareCellsByFirstTextNode,
  contentItemsToTableData,
  isEmptyHeaderCell,
} from './helpers'

export function useVirtualTable(props: UseContentTableProps) {
  const {
    headerRow,
    bodyRows,
    hasHeader,
    hasTitle,
    tableTitle,
    hasFooter,
    tableFooter,
    isEmpty,
  } = contentItemsToTableData(props.content)
  const [sorting, setSorting] = React.useState<SortingState>([])

  const firstBodyRow = bodyRows[0]

  const columns = !firstBodyRow
    ? []
    : firstBodyRow.map((_bodyCell, bodyCellIndex) => {
        const headerContent = headerRow?.[bodyCellIndex] || []
        const HeaderCell = isEmptyHeaderCell(headerContent)
          ? ''
          : () => <HeaderCellComponent content={headerContent} />

        return columnHelper.accessor(row => row[bodyCellIndex], {
          id: String(bodyCellIndex),
          sortingFn: compareCellsByFirstTextNode,
          header: HeaderCell,
          cell: CellComponent,
        })
      })

  const table = useReactTable({
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    data: bodyRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    sortDescFirst: false,
  })

  const rows = React.useMemo(() => table.getRowModel().rows, [sorting])
  const headerGroups = React.useMemo(() => table.getHeaderGroups(), [sorting])

  return {
    ...table,
    isEmpty,
    hasHeader,
    hasTitle,
    tableTitle,
    hasFooter,
    tableFooter,
    sorting,
    rows,
    headerGroups,
  }
}
