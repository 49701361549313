import { Icon } from '@scm/ui-component-icon'
import { Bem } from '@scm/ui-core'

import { BlockquoteProps } from './type'

const blockquote = Bem('Blockquote')

export const Blockquote = ({ children, className }: BlockquoteProps) => {
  return (
    <div data-component="blockquote" className={blockquote({}, [className])}>
      <Icon
        icon="OpeningQuote"
        className={blockquote('Icon', { state: 'open' })}
      />
      <Icon
        icon="ClosingQuote"
        className={blockquote('Icon', { state: 'close' })}
      />
      <blockquote className={blockquote('Quote')}>{children}</blockquote>
    </div>
  )
}
