import { useEffect } from 'react'

import { ScmEmbedAttrs } from '../type'

declare global {
  interface HTMLScriptElement {
    ['data-turtl-assets-hostname']: string
    ['data-turtl-script']: string
  }
}
const defaultLinkText = 'Click to read'
const defaultEmbedType = 'animation'
const defaultDisplayMode = 'lightbox'
const defaultAnimationMode = 'hover'
const defaultColor = '#1eb1c7'

export const Turtl = (attrs: ScmEmbedAttrs) => {
  const {
    id,
    href: src,
    title = 'Turtl embed',
    ['link-text']: linkText,
    ['embed-type']: embedType,
    ['width']: width,
    ['display-mode']: displayMode,
    ['animation-mode']: animationMode,
    ['color']: color,
  } = attrs

  useEffect(() => {
    const script = document.createElement('script')
    script.setAttribute('data-turtl-script', 'embed')
    script.setAttribute('data-turtl-assets-hostname', 'https://assets.turtl.co')
    script.src = 'https://app-static.turtl.co/embed/turtl.embed.v1.js'
    script.type = 'text/javascript'
    const firstScriptOnPage = document.getElementsByTagName('script')[0]
    firstScriptOnPage?.parentNode?.insertBefore(script, firstScriptOnPage)
  }, [])

  if (!id || !src) {
    return (
      <p>{`Error: Turtl embeds should have non-empty "data-turtl-story-id" & "href" attributes`}</p>
    )
  }

  return (
    <a
      className="turtl-embed"
      style={{
        ...(width && { width: `${width}px` }),
        maxWidth: '100%',
      }}
      data-turtl-embed-type={embedType || defaultEmbedType}
      data-turtl-link-text={linkText || defaultLinkText}
      data-turtl-width={width}
      data-turtl-display-mode={displayMode || defaultDisplayMode}
      data-turtl-animation-mode={animationMode || defaultAnimationMode}
      data-turtl-color={color || defaultColor}
      data-turtl-story-id={id}
      href={src}
      title={title}
      rel="noreferrer"
    >
      {linkText || defaultLinkText} {title}
    </a>
  )
}
