import { kebabCase } from 'lodash'

export const sanitiseString = (value: string) => {
  if (!value) return ''
  const invalidChars = [
    '"',
    "'",
    '=',
    '!',
    '+',
    '#',
    '*',
    '~',
    ';',
    '^',
    '(',
    ')',
    '<',
    '>',
    '[',
    ']',
    ',',
    '&',
  ]
  let sanitisedString = value
  invalidChars.forEach((char: string) => {
    sanitisedString = sanitisedString.replace(new RegExp('\\' + char, 'g'), '')
  })
  return kebabCase(sanitisedString)
}
