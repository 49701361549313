import isEqual from 'lodash/isEqual'
import { useEffect, useMemo, useRef, useState } from 'react'

import { useSelector } from '@scm/xstate'

import { getAdsRender } from './actions'
import { getAdsBreakpoint } from './adsList.constants'
import { useAdsService } from './adsProvider'
import { adsModel } from './model'
import { AdDisplayStatus, AdPos, AdsContext, AdsMetadata } from './types'
import { getTargetingFromMetadata } from './updateMetadata'
import { storeSelector } from '@scm/redux-store'
import { log } from './log'

export const useAdsSend = () => useAdsService().send

export const useHasAdBlocker = () => {
  const service = useAdsService()
  return useSelector(
    service,
    state => state.matches('checkingBlocked') || state.matches('blocked'),
  )
}

export const isAdEnabled = (context: AdsContext, pos: AdPos): boolean =>
  context.enabledAds === 'all' ? true : context.enabledAds.includes(pos)

export const useAreAllAdsEnabled = (ads: AdPos[]) => {
  const service = useAdsService()
  return useSelector(service, state => {
    const disabledAd = ads.find(pos => !isAdEnabled(state.context, pos))
    return !disabledAd
  })
}

export const useIsAdEnabled = (pos: AdPos): boolean => {
  const service = useAdsService()
  const adEnabled = useSelector(service, state =>
    isAdEnabled(state.context, pos),
  )

  if (typeof window !== 'undefined') {
    const adsBreakpoint = getAdsBreakpoint(pos)
    if (adsBreakpoint && window.innerWidth < adsBreakpoint) {
      return false
    }
  }
  return adEnabled
}

export const useIsReadyToLoadAds = () => {
  const service = useAdsService()
  return useSelector(service, state => {
    const isLoaded = state.matches('unblocked.loaded')
    return Boolean(state.context.targeting && isLoaded)
  })
}

const metaToTargeting = (metadata: AdsMetadata | null) =>
  metadata ? getTargetingFromMetadata(metadata) : null

export const useShowAds = (metadata: AdsMetadata | null) => {
  const service = useAdsService()
  const { gtagReady } = storeSelector(s => s.layout)
  const canUpdateMetadata = useSelector(service, s =>
    Boolean(
      s.matches('unblocked.waitForInitialTargeting') ||
        s.matches('unblocked.loaded'),
    ),
  )
  const context = useSelector(service, s => s.context)

  const ref = useRef<AdsMetadata | null>(null)

  const container = useMemo(() => {
    const targeting = metaToTargeting(metadata)
    return {
      adUnitPathCode: `${context?.networkId}/${context?.parentAdUnit}/${targeting?.pattern}`,
    }
  }, [metadata, context?.networkId, context?.parentAdUnit])

  useEffect(() => {
    if (gtagReady) {
      const targeting = metaToTargeting(metadata)
      if (!isEqual(metadata, ref.current)) {
        log.info('useShowAds > showWelcomeAds', Date.now())
        service.send(adsModel.events.showWelcomeAds(targeting))
      }
      log.info('useShowAds > showBigskyAd', Date.now())
      service.send(adsModel.events.showBigskyAd(targeting))
      if (canUpdateMetadata) {
        log.info('useShowAds > updateTargeting')
        service.send(adsModel.events.updateTargeting(targeting))
      }
    }
  }, [metadata, gtagReady])

  log.info('useShowAds > Cont', container)
  return container
}

export const useStateValue = () => {
  const service = useAdsService()
  const [value, setValue] = useState<any>('')
  const stateValue = useSelector(service, state => state.value)

  useEffect(() => {
    if (!isEqual(value, stateValue)) setValue(stateValue)
  }, [value, stateValue])

  return value || stateValue
}

export const useStateContext = () => {
  const service = useAdsService()
  return useSelector(service, state => state.context)
}

export const useTargeting = () => {
  const service = useAdsService()
  return useSelector(service, state => state.context.targeting)
}
export const useTargetingWithMetaData = (metadata: AdsMetadata) => {
  return getTargetingFromMetadata(metadata)
}

export const useIsAdActive = (pos: AdPos): boolean => {
  const service = useAdsService()
  return useSelector(service, state => Boolean(state.context.slots[pos]))
}

export const useGoogleTag = () => {
  const service = useAdsService()
  return useSelector(service, state => {
    const { gtag } = state.context
    if (!gtag) return null
    return gtag
  })
}

export const useIsAdRender = (pos: AdPos) => {
  const googleTag = useGoogleTag() as typeof googletag | null
  const [isAdsRender, setIsAdsRender] = useState<AdDisplayStatus>('init')

  useEffect(() => {
    getAdsRender(pos, googleTag, (v: AdDisplayStatus) => {
      setIsAdsRender(v)
    })
  }, [googleTag, pos])

  return isAdsRender
}

export const useAdsRender = () => {
  const googleTag = useGoogleTag() as typeof googletag
  return (posList: AdPos[], callback: (value: AdPos) => void) => {
    if (googleTag) {
      googleTag.cmd.push(function () {
        googleTag
          .pubads()
          .addEventListener('slotRenderEnded', function (event: any) {
            const posTarget = event.slot.getTargeting('pos').toString() as AdPos
            if (posList.includes(posTarget) && !event.isEmpty) {
              callback(posTarget)
            }
          })
      })
    }
  }
}
