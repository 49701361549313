import { useEffect, useRef } from 'react'

import { ScmEmbedAttrs } from '../type'
import { setDatawrapperIframeHeights } from '../utils'

export const Datawrapper = (attrs: ScmEmbedAttrs) => {
  const { id, src, title = 'Datawrapper embed' } = attrs
  const embedContainerRef = useRef<HTMLParagraphElement | null>(null)

  useEffect(() => {
    window.addEventListener('message', setDatawrapperIframeHeights)
    return () => {
      window.removeEventListener('message', setDatawrapperIframeHeights)
    }
  }, [src])

  if (!id || !src) {
    return (
      <p>{`Error: Datawrapper embeds should have non-empty "id" & "src" attributes`}</p>
    )
  }

  return (
    <div ref={embedContainerRef}>
      <iframe
        title={title}
        aria-label={title}
        id={id}
        src={src}
        width="100%"
        data-external="1"
      />
    </div>
  )
}
