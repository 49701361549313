const wrapper = (scope: string, fn: any) => {
  if (process.env.NODE_ENV === 'test') return () => {}
  return fn(scope)
}

const info =
  (scope: string) =>
  (...args: string[]) =>
    // eslint-disable-next-line no-console
    console.log(`%c[SCM/${scope}]`, 'color: #3dd8ba', ...args)

const warn =
  (scope: string) =>
  (...args: string[]) =>
    // eslint-disable-next-line no-console
    console.warn(`%c[SCM/${scope}]`, 'color: #fcbc54', ...args)

const error =
  (scope: string) =>
  (...args: (string | Error)[]) =>
    // eslint-disable-next-line no-console
    console.error(
      `%c[SCM/${scope}]`,
      'color: #ef509c',
      ...args.map(err => (err instanceof Error ? err.message : err)),
    )

function mockFn() {}
export const createLogger = (scope: string, dev = false) => {
  if (dev) {
    const location = typeof window !== 'undefined' ? window.location.host : ''
    if (!location.startsWith('staging.') && !location.startsWith('pr-')) {
      return {
        info: mockFn,
        warn: mockFn,
        error: mockFn,
      }
    }
  }
  return {
    info: wrapper(scope, info),
    warn: wrapper(scope, warn),
    error: wrapper(scope, error),
  }
}
