import React, { useEffect, useRef } from 'react'

import { ScmEmbedAttrs } from '../type'

const config = {
  title: 'NxtGen Digizine',
  className: 'fbo-embed',
  ratio: '3:2',
  lightbox: 'yes',
  width: '100%',
  height: 'auto',
  style: {
    maxWidth: '100%',
  },
}

const getNxtGenDigizineUrl = (id: string) =>
  `https://nxtgennetwork.serveddigizine.com/view/${id}`

const getEmbedScriptUrl = (id: string) =>
  `https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&amp;hid=${id}`

const getScriptSrcUrl = async (id: string): Promise<string> => {
  try {
    const response = await fetch(getEmbedScriptUrl(id))
    const scriptUrl = await response.text()
    // scriptUrl looks like: https://d33i2vgywgme2s.cloudfront.net/render/4.10.2-hotfix.1-R10475/embed.js
    return scriptUrl
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  return ''
}

const createElementScript = (src?: string) => {
  const script = document.createElement('script')
  script.defer = true
  script.src = src || ''
  return script
}

export const NxtGen = (attrs: ScmEmbedAttrs) => {
  const { id = '', title = config.title } = attrs

  const refEmbed = useRef<HTMLAnchorElement | null>(null)

  useEffect(() => {
    if (!id) return

    getScriptSrcUrl(id).then(src => {
      if (!src) return
      refEmbed.current?.parentNode?.append(createElementScript(src))
    })
  }, [id])

  if (!id) {
    return <p>{`Error: NxtGen embeds should have non-empty "id" attribute`}</p>
  }

  return (
    <a
      ref={refEmbed}
      href={getNxtGenDigizineUrl(id)}
      className={config.className}
      data-fbo-ratio={config.ratio}
      data-fbo-lightbox={config.lightbox}
      data-fbo-width={config.width}
      data-fbo-height={config.height}
      style={config.style}
    >
      {title}
    </a>
  )
}
