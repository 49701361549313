import { useEffect, useRef } from 'react'

import { ScmEmbedAttrs } from '../type'

const getMainScript = () => {
  const script = document.createElement('script')
  script.async = true
  script.src = 'https://fast.wistia.com/assets/external/E-v1.js'

  return script
}

export const Wistia = ({
  formattedPaddingTop,
  ...attrs
}: { formattedPaddingTop: string } & ScmEmbedAttrs) => {
  const { 'padding-top': paddingTop, src, title = 'Wistia video embed' } = attrs
  const embedContainerRef = useRef<HTMLParagraphElement | null>(null)

  useEffect(() => {
    embedContainerRef.current?.parentNode?.append(getMainScript())
  }, [])

  if (!paddingTop || !src || !formattedPaddingTop) {
    return (
      <p>{`Error: Wistia embeds should have non-empty "padding-top" & "src" attributes`}</p>
    )
  }

  return (
    <div
      ref={embedContainerRef}
      className="wistia_responsive_padding"
      style={{ padding: `${formattedPaddingTop} 0 0 0`, position: 'relative' }}
    >
      <div
        className="wistia_responsive_wrapper"
        style={{
          height: '100%',
          left: '0',
          position: 'absolute',
          top: '0',
          width: '100%',
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={src}
          title={title}
          allow="autoplay; fullscreen"
          // eslint-disable-next-line react/no-unknown-property
          allowTransparency
          frameBorder="0"
          scrolling="no"
          className="wistia_embed"
          name="wistia_embed"
          allowFullScreen
        />
      </div>
    </div>
  )
}
