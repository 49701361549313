import type { CellContext, Row } from '@tanstack/react-table'
import { createColumnHelper } from '@tanstack/react-table'
import naturalCompare from 'string-natural-compare'

import { Content } from '../content'
import { ContentParagraph, ContentTableRow } from '../type'
import { cleanupTableRows } from './cleanupContent'

export const columnHelper = createColumnHelper<ContentParagraph[][]>()

export function CellComponent(
  props: CellContext<ContentParagraph[][], ContentParagraph[]>,
) {
  return (
    <Content
      content={props.getValue()}
      paragraphVariant="content-table"
      doWrap={false}
    />
  )
}

export function HeaderCellComponent({
  content,
}: {
  content?: ContentParagraph[]
}) {
  return (
    <Content
      content={content || []}
      paragraphVariant="content-table"
      doWrap={false}
    />
  )
}

export function isEmptyHeaderCell(headerCell?: ContentParagraph[]): boolean {
  if (!headerCell || headerCell.length === 0) return true

  const content = headerCell[0].content
  if (
    !content ||
    content.length === 0 ||
    (content[0].type === 'text' && !content[0].text)
  )
    return true

  return false
}

function unwrapRowsAndCells(rows: ContentTableRow[]) {
  return rows.map(row => row.content.map(cell => cell.content))
}

function isShortRow(
  currentRow: ContentParagraph[][],
  bodyRow: ContentParagraph[][],
) {
  return bodyRow.length > 1 && currentRow.length < bodyRow.length
}

function getTableTitle(
  firstRow: ContentParagraph[][],
  restRows: ContentParagraph[][][],
) {
  if (!firstRow?.length || restRows.length === 0) return []

  const firstRowLooksLikeTitle = isShortRow(firstRow, restRows[0])

  return firstRowLooksLikeTitle ? firstRow[0] : []
}

function getTableFooter(restRows: ContentParagraph[][][]) {
  if (restRows.length < 2) return []

  const lastRow = restRows[restRows.length - 1]
  const lastRowLooksLikeFooter = isShortRow(lastRow, restRows[0])

  return lastRowLooksLikeFooter ? lastRow[0] : []
}

function isHeaderRow(row?: ContentTableRow) {
  return row?.content?.[0]?.type === 'tableHeader'
}

function looksLikeHeaderRow(row: ContentParagraph[][]) {
  return row.some(cell => {
    const firstContentNode = cell[0]?.content[0]
    if (!firstContentNode || firstContentNode.type !== 'text') {
      return false
    }
    return firstContentNode.marks?.some(mark => mark.type === 'bold')
  })
}

export function contentItemsToTableData(rows: ContentTableRow[]) {
  let tableRows = cleanupTableRows(unwrapRowsAndCells(rows))
  const isEmpty = tableRows.length === 0

  if (isEmpty) {
    return {
      hasHeader: false,
      headerRow: [],
      hasTitle: false,
      tableTitle: [],
      hasFooter: false,
      tableFooter: [],
      bodyRows: [],
    }
  }

  const [firstRow, ...restRows] = tableRows

  // check if table has title
  let hasTitle = false
  const tableTitle = getTableTitle(firstRow, restRows)

  if (tableTitle.length > 0) {
    hasTitle = true
    tableRows = restRows
  }

  // check if table has footer
  let hasFooter = false
  const tableFooter = getTableFooter(restRows)

  if (tableFooter.length > 0) {
    hasFooter = true
    tableRows.pop()
  }

  const [headerRow, ...bodyRows] = tableRows

  // check if table has header
  const hasHeader =
    isHeaderRow(rows[0]) ||
    isHeaderRow(rows[1]) ||
    looksLikeHeaderRow(headerRow)

  return {
    isEmpty,
    hasTitle,
    tableTitle,
    hasFooter,
    tableFooter,
    hasHeader,
    headerRow: hasHeader ? headerRow : [],
    bodyRows: hasHeader ? bodyRows : tableRows,
  }
}

function getContentOfFirstTextNode(paragraph?: ContentParagraph[]) {
  const firstContentNode = paragraph?.[0]?.content?.[0]

  if (firstContentNode?.type !== 'text') {
    return ''
  }

  return firstContentNode.text
}

export function compareCellsByFirstTextNode(
  rowA: Row<ContentParagraph[][]>,
  rowB: Row<ContentParagraph[][]>,
  columnId: string,
) {
  const cellA = rowA.renderValue<ContentParagraph[]>(columnId)
  const cellB = rowB.renderValue<ContentParagraph[]>(columnId)
  const textA = getContentOfFirstTextNode(cellA)
  const textB = getContentOfFirstTextNode(cellB)

  return naturalCompare(textA, textB)
}
