import {
  ContentCustomMarksNames,
  ContentItem,
  ContentJSON,
  ContentLinkMark,
  ContentSubscriptMark,
  ContentSuperscriptMark,
  Marks,
} from './type'

export const getMarksByType = (
  tags: ContentCustomMarksNames[],
  marks: Marks,
) => {
  const foundMarks = tags.map(tag => marks.find(m => m.type === tag))

  return foundMarks as [
    ContentLinkMark | undefined,
    ContentSubscriptMark | undefined,
    ContentSuperscriptMark | undefined,
  ]
}

export const getClassNameModificators = (marks: Marks) => {
  return marks
    .map(m => m.type)
    .reduce((acc, item) => ({ ...acc, [item]: true }), {})
}

export const setDatawrapperIframeHeights = (event: MessageEvent) => {
  if (event.data['datawrapper-height'] !== undefined) {
    const iframes = document.querySelectorAll('iframe')
    Object.keys(event.data['datawrapper-height']).forEach(id => {
      for (let i = 0; i < iframes.length; i++)
        if (iframes[i].contentWindow === event.source) {
          const height = event.data['datawrapper-height'][id] + 'px'
          iframes[i].style.height = height
        }
    })
  }
}

export const getContent = (content: ContentJSON) => {
  if (!content) {
    return []
  }

  const revealAdIndex = findAdInsertIndex(content)

  if (revealAdIndex === -1) {
    return content
  }
  const contentCopy = insertAd(content, revealAdIndex)
  return contentCopy
}

export const findAdInsertIndex = (content: ContentJSON) => {
  let charsAfterNative = 0
  let charsBeforeNative = 0
  let charsBeforeNativeSecond = 0
  let revealAdIndex = -1
  let indexBeforeNative = -1
  let startAfterNative = false

  content.forEach((item, index) => {
    if (revealAdIndex === -1) {
      if (isNativeAd(item)) {
        startAfterNative = true
      }
      if (charsAfterNative > 1000) {
        revealAdIndex = index
      }
      if (indexBeforeNative === -1) {
        if (charsBeforeNative > 1300) {
          indexBeforeNative = index
        }
      } else {
        if (charsBeforeNativeSecond > 1000) {
          revealAdIndex = indexBeforeNative
        }
      }
      const characters = countCharacters(
        item,
        charsBeforeNative,
        charsBeforeNativeSecond,
        indexBeforeNative,
        startAfterNative,
        charsAfterNative,
      )

      charsBeforeNative = characters.charsBeforeNative
      charsBeforeNativeSecond = characters.charsBeforeNativeSecond
      charsAfterNative = characters.charsAfterNative
    }
  })
  return revealAdIndex
}

export const isNativeAd = (item: ContentItem) => {
  return (
    item.type === 'ad' && (item.pos === 'native_1v' || item.pos === 'native_2v')
  )
}

export const countCharacters = (
  item: ContentItem,
  charsBeforeNative: number,
  charsBeforeNativeSecond: number,
  indexBeforeNative: number,
  startAfterNative: boolean,
  charsAfterNative: number,
) => {
  if (item.type === 'paragraph' && 'content' in item) {
    item.content.forEach(contentItem => {
      if (contentItem.type === 'text' && 'text' in contentItem) {
        if (indexBeforeNative === -1 && !startAfterNative) {
          charsBeforeNative += contentItem.text.length
        }
        if (indexBeforeNative !== -1 && !startAfterNative) {
          charsBeforeNativeSecond += contentItem.text.length
        }
        if (startAfterNative) {
          charsAfterNative += contentItem.text.length
        }
      }
    })
  }
  if (item.type === 'image') {
    charsAfterNative = 0
  }

  return { charsBeforeNative, charsBeforeNativeSecond, charsAfterNative }
}

export const insertAd = (content: ContentJSON, revealAdIndex: number) => {
  const contentCopy = [...content]
  contentCopy.splice(revealAdIndex, 0, { type: 'ad', pos: 'reveal_1v' })
  return contentCopy
}
